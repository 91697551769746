export default function reducer(state, action) {
  switch (action.type) {
    case "increase":
      let stepIncrease =
        action?.moderation === "Upload" || action?.moderation === "Postal"
          ? state.activeStep + 3
          : state.activeStep + 1;
      return {
        ...state,
        activeStep: stepIncrease,
      };
    case "decrease":
      let stepDecrease =
        action?.moderation === "Upload" || action?.moderation === "Postal"
          ? state.activeStep - 3
          : state.activeStep - 1;
      return {
        ...state,
        activeStep: stepDecrease,
      };
    case "addDate":
      return {
        ...state,
        formValues: {
          ...state.formValues,
          unavailable_dates: [
            ...state.formValues["unavailable_dates"],
            {
              start_date: {
                value: "",
                error: "",
                validate: "firstDate",
              },
              end_date: {
                value: "",
                error: "",
                validate: "lastDate",
              },
              easter_indicator: "N",
            },
          ],
        },
      };
      case "removeDate":
        return {
          ...state,
          formValues: {
            ...state.formValues,
            unavailable_dates: [
              ...state.formValues["unavailable_dates"].filter((_, index) => index !== action.index)
            ],
          },
        };
    case "submit":
      let activeStep = action?.step === "97" ? 7 : 6;
      return {
        ...state,
        formValues: {
          ...state.formValues,
          submit: "Y",
        },
        activeStep: activeStep,
      };
    case "form-value":
      if (action.name === "moderation_type") {
        if (action.fieldValue === "Visiting") {
          return {
            ...state,
            formValues: {
              ...state.formValues,
              [action.name]: {
                ...state.formValues[action.name],
                value: action.fieldValue ? action.fieldValue : "",
              },
              centre_host_venue_indicator: "Y",
            },
          };
        } else {
          return {
            ...state,
            formValues: {
              ...state.formValues,
              [action.name]: {
                ...state.formValues[action.name],
                value: action.fieldValue ? action.fieldValue : "",
              },
              centre_host_venue_indicator: "N",
            },
          };
        }
      } else if (action.name === "reset_values") {
        return {
          ...state,
          formValues: {
            ...state.formValues,
            a_level_activities: [...state.allData["a_level_activities"]],
            as_level_activities: [...state.allData["as_level_activities"]],
          },
        };
      } else
        return {
          ...state,
          formValues: {
            ...state.formValues,
            [action.name]: {
              ...state.formValues[action.name],
              value: action.fieldValue ? action.fieldValue : "",
            },
          },
        };
    case "form-centre-venue":
      return {
        ...state,
        formValues: {
          ...state.formValues,
          centre_venue: {
            ...state.formValues.centre_venue,
            [action.name]: {
              ...state.formValues.centre_venue[action.name],
              value: action.fieldValue ? action.fieldValue : "",
            },
          },
        },
      };
    case "save-date":
      const inputDate = new Date(action.fieldValue);
      const year = inputDate.getFullYear();
      const month = String(inputDate.getMonth() + 1).padStart(2, "0");
      const day = String(inputDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      let data = state.formValues[action.name];
      data[action.id] =
        action.flag === "start_date"
          ? {
              ...data[action.id],
              start_date: {
                ...data[action.id]["start_date"],
                value: formattedDate,
              },
            }
          : {
              ...data[action.id],
              end_date: {
                ...data[action.id]["end_date"],
                value: formattedDate,
              },
            };

      return {
        ...state,
        formValues: {
          ...state.formValues,
          unavailable_dates: [...data],
        },
      };
    case "form-error":
      if (action.flag === "centre_venue") {
        return {
          ...state,
          formValues: {
            ...state.formValues,
            centre_venue: {
              ...state.formValues.centre_venue,
              [action.name]: {
                ...state.formValues.centre_venue[action.name],
                error: action.error,
              },
            },
          },
        };
      } else if (action.name !== "unavailable_dates")
        return {
          ...state,
          formValues: {
            ...state.formValues,
            [action.name]: {
              ...state.formValues[action.name],
              error: action.error,
            },
          },
        };
      else {
        let t = state.formValues[action.name];
        t[action.index][action.flag]["error"] = action.error;
        return {
          ...state,
          formValues: {
            ...state.formValues,
            [action.name]: t,
          },
        };
      }
    case "center":
      return {
        ...state,
        formValues: {
          ...state.formValues,
          centre_name: action.centre_name,
          centre_number: action.centre_number,
        },
      };
    case "form-static-value":
      if (action.name === "centre_host_venue_indicator") {
        return {
          ...state,
          formValues: {
            ...state.formValues,
            a_level_activities: [...state.allData["a_level_activities"]],
            as_level_activities: [...state.allData["as_level_activities"]],
            centre_host_venue_indicator: action.fieldValue
              ? action.fieldValue
              : "",
          },
        };
      } else {
        return {
          ...state,
          formValues: {
            ...state.formValues,
            [action.name]: action.fieldValue,
          },
          activeStep: action.initial ? 0 : state.activeStep,
        };
      }
    case "unavailable_dates":
      let newArray = action.fieldValue.map((item, index) => {
        let newItem = {
          start_date: {
            value: action.visitId ? item.start_date : item.start_date.value,
            error: "",
            validate: "firstDate",
          },
          end_date: {
            value: action.visitId ? item.end_date : item.end_date.value,
            error: "",
            validate: "lastDate",
          },
          easter_indicator: item.easter_indicator,
        };
        if (item.easter_indicator === "Y") {
          newItem.start_date.required = true;
          newItem.end_date.required = true;
        }

        return newItem;
      });

      newArray.sort(
        (a, b) =>
          (b.easter_indicator === "Y" ? 1 : 0) -
          (a.easter_indicator === "Y" ? 1 : 0)
      );
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: newArray,
        },
      };
    case "set-alldata":
      return {
        ...state,
        allData: { ...action.data },
      };
    case "set-table":
      const dataValue = JSON.parse(JSON.stringify(state.formValues));
      if (
        ["off_site", "filmed", "outdoor", "indoor", "being_filmed"].includes(
          action.name
        )
      ) {
        if (action.value === "on")
          dataValue[action.activities][action.index][action.name][
            "ticked"
          ] = false;
        else
          dataValue[action.activities][action.index][action.name][
            "ticked"
          ] = true;
      } else if (
        ["extra_females", "extra_males", "males", "females"].includes(
          action.name
        )
      ) {
        if (/^[0-9]\d*$/.test(action.value) || action.value === "") {
          if (["extra_females", "extra_males"].includes(action.name)) {
            dataValue[action.activities][action.index][action.name] =
              action.value === "" ? true : action.value;
          }else {
            dataValue[action.activities][action.index][action.name] = action.value;
          }
        }
      }else {
        dataValue[action.activities][action.index][action.name] = action.value;
      } 
      return { ...state, formValues: dataValue };
    case "refresh":
      return {
        ...state,
        formValues: {
          ...action.data,
          centre_name: state.formValues.centre_name,
          centre_number: state.formValues.centre_number,
        },
      };
    default:
      return state;
  }
}
