import React, { useCallback, useContext, useEffect, useState } from "react";
import StepActions from "../common/StepActions";
import {
  Typography,
  Box,
  TextField,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  TextareaAutosize,
  Divider,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { MainContext } from "../../context/Context";
import axios from "axios";
import { QualMapping } from "../../utils/constants";
import { useAuth } from "react-oidc-context";

export default function FourthStep() {
  const auth = useAuth();
  const accessToken = auth.user?.access_token;
  const location = useLocation();
  const [editAddress, setEditAddress] = useState(true);
  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    handleSave,
    handleCenter,
    variant,
    margin,
  } = useContext(MainContext);
  const {
    centre_venue,
    level,
    centre_host_venue_indicator,
    centre_number,
    moderation_type,
  } = formValues;
  useEffect(() => {
    if (location.state?.name.split(",").includes(QualMapping.ENTRY)) {
      if (centre_venue?.address_line_1?.value === "") {

        axios
          .get(
            process.env.REACT_APP_API_BASEURL +
              `centresaddress/${centre_number}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              },
            }
          )
          .then(res => {
            handleCenter(res.data);
          })
          .catch(err => console.log(err));
      }
    }
  }, [location]);

  const handleVenue = e => {
    if (e.target.value === "Y") {

      axios
        .get(
          process.env.REACT_APP_API_BASEURL + `centresaddress/${centre_number}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            },
          }
        )
        .then(res => {
          handleCenter(res.data);
        })
        .catch(err => console.log(err));
    } else {
      handleCenter({
        city: "",
        county: "",
        address_line_1: "",
        address_line_2: "",
        postcode: "",
        country_code: "",
      });
    }
    handleChange(e);
  };
  const { address_line_1, address_line_2, city, county, postcode } =
    centre_venue;
  const isError = useCallback(() => {
    if (centre_host_venue_indicator === "N") {
      return false;
    } else {
      return Object.keys({
        address_line_1,
        address_line_2,
        city,
        county,
        postcode,
      }).some(
        name =>
          (formValues["centre_venue"][name].required &&
            !formValues["centre_venue"][name].value) ||
          formValues["centre_venue"][name].error
      );
    }
  }, [formValues, address_line_1, address_line_2, city, county, postcode]);
  return (
    <>
      <Box sx={{ mt: 5, mb: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {location.state?.name.split(",").includes(QualMapping.ENTRY)
            ? `Venue Details`
            : `Centre and visit details`}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {(location.state?.name.split(",").includes(QualMapping.ALEVEL) ||
          location.state?.name.split(",").includes(QualMapping.ASLEVEL)) && (
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Which levels are you entering?
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={level.value}
              onChange={handleChange}
              name="level"
            >
              <FormControlLabel
                value="AS and A Level"
                control={<Radio />}
                label="AS and A Level"
              />
              <FormControlLabel
                value="A Level Only"
                control={<Radio />}
                label="A Level only"
              />
              <FormControlLabel
                value="AS Level Only"
                control={<Radio />}
                label="AS Level only"
              />
            </RadioGroup>
            <Divider variant="fullWidth" />
          </Grid>
        )}
        {location.state?.name !== QualMapping.ENTRY && (
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Is your centre willing to host a moderation day?
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={centre_host_venue_indicator}
              onChange={e => handleVenue(e)}
              name="centre_host_venue_indicator"
            >
              <FormControlLabel value="Y" control={<Radio />} label="Yes" />
              <FormControlLabel value="N" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
        )}
        {(centre_host_venue_indicator === "Y" ||
          location.state?.name === QualMapping.ENTRY) && (
          <>
            <Grid item xs={12}>
              {location.state?.name !== QualMapping.ENTRY && (
                <Divider variant="fullWidth" sx={{ mb: 2 }} />
              )}
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                Venue address
              </Typography>
              {location.state?.name !== QualMapping.ENTRY && (
                <Typography variant="caption">
                  This is the address the moderator and visiting centres should
                  arrive to participate in moderation, rather than the main
                  school address
                </Typography>
              )}
              <Typography variant="subtitle2">Address line 1*</Typography>
              <TextField
                variant={variant}
                margin={margin}
                name="address_line_1"
                inputProps={{ "data-flag": "centre_venue" }}
                value={centre_venue.address_line_1.value}
                onChange={handleChange}
                error={!!centre_venue.address_line_1.error}
                helperText={centre_venue.address_line_1.error}
                required={centre_venue.address_line_1.required}
                disabled={editAddress}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Address line 2</Typography>
              <TextField
                variant={variant}
                margin={margin}
                name="address_line_2"
                inputProps={{ "data-flag": "centre_venue" }}
                value={centre_venue.address_line_2.value}
                onChange={handleChange}
                error={!!centre_venue.address_line_2.error}
                disabled={editAddress}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">City*</Typography>
              <TextField
                variant={variant}
                margin={margin}
                name="city"
                inputProps={{ "data-flag": "centre_venue" }}
                value={centre_venue.city.value}
                onChange={handleChange}
                error={!!centre_venue.city.error}
                helperText={centre_venue.city.error}
                required={centre_venue.city.required}
                disabled={editAddress}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">County</Typography>
              <TextField
                variant={variant}
                margin={margin}
                name="county"
                inputProps={{ "data-flag": "centre_venue" }}
                value={centre_venue.county.value}
                onChange={handleChange}
                error={!!centre_venue.county.error}
                helperText={centre_venue.county.error}
                disabled={editAddress}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Postcode*</Typography>
              <TextField
                variant={variant}
                margin={margin}
                name="postcode"
                inputProps={{ "data-flag": "centre_venue" }}
                value={centre_venue.postcode.value}
                onChange={handleChange}
                error={!!centre_venue.postcode.error}
                helperText={centre_venue.postcode.error}
                required={centre_venue.postcode.required}
                disabled={editAddress}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "30%" }}
                onClick={() => setEditAddress(!editAddress)}
              >
                Edit Address
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }} />
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Location notes
              </Typography>

              <TextareaAutosize
                minRows={4}
                maxRows={4}
                style={{ height: "5rem", width: "50%" }}
                value={centre_venue.loc_desc.value}
                onChange={handleChange}
                name="loc_desc"
              />
              {location.state?.name !== QualMapping.ENTRY && (
                <Divider variant="fullWidth" sx={{ mt: 1 }} />
              )}
            </Grid>
            {location.state?.name !== QualMapping.ENTRY && (
              <>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                    What facilities are available for external candidates e.g.
                    base when not being assessed, changing room, showers etc.
                  </Typography>

                  <TextareaAutosize
                    minRows={4}
                    maxRows={4}
                    style={{ height: "5rem", width: "50%" }}
                    onChange={handleChange}
                    value={centre_venue.facilities_desc.value}
                    name="facilities_desc"
                    inputProps={{ "data-flag": "centre_venue" }}
                  />
                  <Divider variant="fullWidth" sx={{ mt: 1 }} />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography variant="caption">
                    Please note: OCR will contact centres willing to host where
                    they are needed. Being a host previously does not
                    automatically indicate you will be hosting again. Please
                    also note, there are no advantages to being host centre,
                    beyond not having to transport your candidates elsewhere.
                  </Typography>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <StepActions
        isError={isError}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSave={handleSave}
      />
    </>
  );
}
