import * as React from "react";
import { useState, useRef } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  MenuItem,
  Select,
  FormControl,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import "./marksubmission.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCandidates,
  handleChange,
  resetCandidateData,
  calculateMarks,
} from "../../../store/reducers/candidateReducer";
import { selectActivity } from "../../../store/reducers/activityReducer";
import { selectRole } from "../../../store/reducers/roleReducer";
import {
  tableInputStyle,
  disabledColumnValue,
  disabledOverallMarkColumnValue,
} from "../../../utils/Theme";
import { selectExceptionActivity } from "../../../store/reducers/exceptionalActivityReducer";
import { isSecondEventMarkDisabled, isThirdEventIdDisabled, isThirdEventMarkDisabled, isDisabledSex, isFirstEventDisabled, isFirstEventMarkDisabled, isSecondEventIdDisabled } from "../../../utils/commonFunctions";

export default function ALevelTable({ qual }) {
  const dispatch = useDispatch();
  const { candidates, selectedActivities = [] } = useSelector(selectCandidates);
  const { activities } = useSelector(selectActivity);
  const { noEvents } = useSelector(selectExceptionActivity);
  const { roles } = useSelector(selectRole);
  const rowData = [...candidates];
  const rows = rowData.map((r, i) => {
    return { ...r, id: i, ...r.a_as_lvl_activities };
  });

  let firstEventOptions = [];
  let secondEventOptions = [];
  let thirdEventOptions = [];
  const [data, setData] = useState(rows);

  /*
     let data = useMemo(
       () => rowData.map((r, i) => {
       return { ...r, id: i };
     })
    ,[],
   );
  */
  const tableRef = useRef();

  const isDisable = (row) =>
    row.original.sex === "Unknown" ||
    row.original.candidate_attendance_status === "A" ||
    row.original.candidate_attendance_status === "W";

  const isDisabled = (row) =>
    row.original.sex === "Unknown" ||
    row.original.candidate_attendance_status === "A" ||
    row.original.candidate_attendance_status === "W";

  const getNumber = (x) => {
    return isNaN(parseInt(x)) ? 0 : parseInt(x);
  };

  const resetMarks = (row, markNumber) => {
    if (markNumber == 1) {
      row._valuesCache.first_event_mark = "";
      data[row.index].first_event_mark = "";
    }

    if (markNumber <= 2) row._valuesCache.second_event_mark = "";

    if (markNumber <= 3) row._valuesCache.third_event_mark = "";

    //params.row._valuesCache.overall_event_mark = "";
    row._valuesCache.overall_mark = calculateMarks(row._valuesCache, {
      payload: { qual: qual },
    });
  };

  const prepopulateEvents = (row, eventType) => {
    const acts =
      row.getValue("sex") == "M"
        ? activities.male_Activities
        : activities.female_Activities;
    let firstEvents =
      acts.filter((x) => x.activity_id == row.getValue("activity_id"))[0]
        ?.first_events || [];
    let secondLevelEvents =
      (firstEvents.length &&
        firstEvents.filter(
          (x) => x.event_id == row.getValue("first_event_id")
        )[0]?.next_level_events) ||
      [];
    let thirdLevelEvents =
      (secondLevelEvents.length &&
        secondLevelEvents.filter(
          (x) => x.event_id == row.getValue("second_event_id")
        )[0]?.next_level_events) ||
      [];
    if (
      row.getValue("sex") &&
      row.getValue("activity_id") &&
      row.getValue("role")
    ) {
      // Check and populate second level events
      if (row.getValue("first_event_id") && secondLevelEvents?.length == 1) {
        if (row.getValue("second_event_id") != secondLevelEvents[0].event_id) {
          // Populate second level event with new value
          row._valuesCache.second_event_id = secondLevelEvents[0].event_id;

          onChangeEvent(
            {
              name: "second_event_id",
              value: row._valuesCache.second_event_id,
              autopopulating: true,
            },
            row,
            activities,
            row.id
          );
        }
      }

      // Check and populate third level events
      if (row.getValue("second_event_id") && thirdLevelEvents?.length == 1) {
        if (row.getValue("second_event_id") != thirdLevelEvents[0].event_id) {
          // Populate second level event with new value
          row._valuesCache.third_event_id = thirdLevelEvents[0].event_id;

          onChangeEvent(
            {
              name: "third_event_id",
              value: row._valuesCache.third_event_id,
              autopopulating: true,
            },
            row,
            activities,
            row.id
          );
        }
      }
    }
  };

  const onChangeEvent = (event, row, activities, id) => {
    data[row.index][event.name] = event.value;

    dispatch(
      handleChange({
        event: {
          name: event.name,
          value: event.value,
          autopopulating: event.autopopulating,
        },
        id: row.id,
        qual: qual,
        data:
          row.getValue("sex") === "M"
            ? activities.male_Activities
            : activities.female_Activities,
        activityTypeCode: "a_as_lvl_activities",
      })
    );
  };

  const columns = [
    {
      accessorKey: "candidate_number",
      header: "Candidate Number",
      enableEditing: false,
      sortingFn: "alphanumeric",
      size: 120,
      Cell: (params) => {
        return (
          <Typography variant="body1" sx={{ width: "100%" }}>
            {params.row.original.candidate_number}
          </Typography>
        );
      },
    },
    {
      accessorKey: "candidate_name",
      header: "Candidate name",
      enableEditing: false,
      size: 200,
      xxxCell: (params) => {
        return (
          <Typography variant="body1" sx={{ width: "100%" }}>
            {params.row.original.candidate_name}
          </Typography>
        );
      },
    },
    {
      accessorKey: "sex",
      header: "Sex assigned at birth",
      enableEditing: false,
      size: 150,
      Cell: (params) => {
        return (
          <Typography variant="body1" sx={{ width: "100%" }}>
            {params.row.original.sex === "M"
              ? "Male"
              : params.row.original.sex === "F"
                ? "Female"
                : ""}
          </Typography>
        );
      },
    },
    {
      accessorKey: "activity_id",
      header: "Activity",
      enableSorting: false,
      enableGlobalFilter: false,
      size: 180,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          data[row.index].activity_id = row._valuesCache.activity_id =
            event.target.value;
          // Clear the role
          data[row.index].role = row._valuesCache.role = "";

          // Clear mark fields from table
          resetMarks(row, 1);

          // Set whether filmed evidence is required (required for all off-site activities)
          const actList =
            row.getValue("sex") === "M"
              ? activities.male_Activities
              : activities.female_Activities;
          row._valuesCache.filmed_evidence_to_be_provided =
            actList.filter((x) => x.activity_id == event.target.value)[0]
              .activity_type == "on-site"
              ? "N"
              : "Y";

          dispatch(
            handleChange({
              event: {
                name: column.id,
                value: event.target.value,
              },
              id: row.id,
              qual: qual,
              data: actList,
              activityTypeCode: "a_as_lvl_activities",
            })
          );
        };

        const actlist =
          selectedActivities && selectedActivities.length
            ? selectedActivities.map((x) => ({
              value: x.activity_id,
              label: x.activity_name,
            }))
            : row.getValue("sex") === "F" && activities?.female_Activities
              ? activities?.female_Activities.map((x) => ({
                value: x.activity_id,
                label: x.activity_name,
              }))
              : row.getValue("sex") === "M" && activities?.male_Activities
                ? activities?.male_Activities.map((x) => ({
                  value: x.activity_id,
                  label: x.activity_name,
                }))
                : [];
        return (
          <Select
            sx={tableInputStyle}
            value={cell.getValue()}
            onChange={onChange}
            disabled={isDisabled(row)}
          >
            {actlist.map((activity) => {
              return (
                <MenuItem key={activity.value} value={activity.value}>
                  {activity.label}
                </MenuItem>
              );
            })}
          </Select>
        );
      },
      editSelectOptions: (params) => {
        return params.row.getValue("sex") === "F" &&
          activities?.female_Activities
          ? activities?.female_Activities.map((x) => ({
            value: x.activity_id,
            label: x.activity_name,
          }))
          : params.row.getValue("sex") === "M" && activities?.male_Activities
            ? activities?.male_Activities.map((x) => ({
              value: x.activity_id,
              label: x.activity_name,
            }))
            : [];
      },
      muiEditTextFieldProps: (params) => ({
        select: true,
        disabled: isDisabled(params.row),
        onChange: (event) => {
          data[params.row.index].activity_id = event.target.value;
          // Clear the role
          params.row._valuesCache.role = "";

          // Clear mark fields from table
          resetMarks(params.row, 1);

          dispatch(
            handleChange({
              event: {
                name: event.target.name,
                value: event.target.value,
              },
              id: params.row.id,
              qual: qual,
              data:
                params.row.getValue("sex") === "M"
                  ? activities.male_Activities
                  : activities.female_Activities,
              activityTypeCode: "a_as_lvl_activities",
            })
          );
        },
      }),
    },
    {
      accessorKey: "role",
      header: "Role",
      enableSorting: false,
      enableGlobalFilter: false,
      size: 110,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          data[row.index].role = event.target.value;

          // Clear mark fields from table
          resetMarks(row, 1);

          // Update the cached value here so that the check for pre-populating has the latest value
          row._valuesCache.role = event.target.value;
          prepopulateEvents(row, "role");

          // Set whether filmed evidence is required (filmed evidence is always required for a Coach role)
          if (event.target.value == "Coach")
            row._valuesCache.filmed_evidence_to_be_provided = "Y";
          else {
            // Set filmed evidence based on activity
            const actList =
              row.getValue("sex") === "M"
                ? activities.male_Activities
                : activities.female_Activities;
            row._valuesCache.filmed_evidence_to_be_provided =
              actList.filter(
                (x) => x.activity_id == row.getValue("activity_id")
              )[0].activity_type == "on-site"
                ? "N"
                : "Y";
          }

          dispatch(
            handleChange({
              event: {
                name: column.id,
                value: event.target.value,
              },
              id: row.id,
              qual: qual,
              data:
                row.getValue("sex") === "M"
                  ? activities.male_Activities
                  : activities.female_Activities,
              activityTypeCode: "a_as_lvl_activities",
            })
          );
        };

        return (
          <Select
            sx={{ ...tableInputStyle, width: "100%" }}
            value={cell.getValue()}
            onChange={onChange}
            disabled={isDisabled(row)}
          >
            {roles &&
              roles.map((role) => (
                <MenuItem key={role.role_name} value={role.role_name}>
                  {role.role_name}
                </MenuItem>
              ))}
          </Select>
        );
      },
    },
    {
      accessorKey: "first_event_id",
      header: "First event/skill/ position",
      enableGlobalFilter: false,
      size: 165,
      enableSorting: false,
      editVariant: "select",
      editSelectOptions: (params) => {
        firstEventOptions =
          params.row.getValue("sex") === "F" &&
            params.row.getValue("activity_id") &&
            activities?.female_Activities &&
            activities?.female_Activities.filter(
              (x) => x.activity_id == params.row.getValue("activity_id")
            )[0].first_events
            ? activities?.female_Activities
              .filter(
                (x) => x.activity_id == params.row.getValue("activity_id")
              )[0]
              .first_events.filter(
                (x) =>
                  x.event_role_name === params.row.getValue("role") &&
                  (x.event_sex_restriction == "None" ||
                    x.event_sex_restriction == "Female")
              )
              .map((x) => ({
                value: x.event_id,
                label: x?.event_display_name,
              }))
            : params.row.getValue("sex") === "M" &&
              params.row.getValue("activity_id") &&
              activities?.male_Activities &&
              activities?.male_Activities.filter(
                (x) => x.activity_id == params.row.getValue("activity_id")
              )[0].first_events
              ? activities?.male_Activities
                .filter(
                  (x) => x.activity_id == params.row.getValue("activity_id")
                )[0]
                .first_events.filter(
                  (x) =>
                    x.event_role_name === params.row.getValue("role") &&
                    (x.event_sex_restriction == "None" ||
                      x.event_sex_restriction == "Male")
                )
                .map((x) => ({
                  value: x.event_id,
                  label: x?.event_display_name,
                }))
              : [];
        return firstEventOptions;
      },
      muiEditTextFieldProps: (params) => {
        return {
          select: true,
          id: "first_" + params.row.id,
          disabled: isFirstEventDisabled(params, activities),
          onChange: (event) => {
            // Update the cached value here so that the check for pre-populating has the latest value
            params.row._valuesCache.first_event_id = event.target.value;

            resetMarks(params.row, 1);

            onChangeEvent(event.target, params.row, activities, 1);

            // Do the prepopulate after the change event, the change event is resetting fields and preventing the prepopulate
            prepopulateEvents(params.row, "first_event_id");

            // An exception case, Athletics and Pole Vaulting is the only off-site athletics event so the filming is required
            if (
              params.row._valuesCache.activity_id == "4" &&
              params.row._valuesCache.first_event_id == "35"
            )
              params.rows._valuesCache.filmed_evidence_to_be_provided = "Y";
          },
        };
      },

      Cell: (params) => {
        return (
          <Typography>
            {params.row.getValue("sex") === "F" &&
              params.row.getValue("activity_id") &&
              params.row.getValue("first_event_id") &&
              activities?.female_Activities
              ? activities?.female_Activities
                .filter(
                  (x) => x.activity_id == params.row.getValue("activity_id")
                )[0]
                ?.first_events?.filter(
                  (x) => x.event_id == params.cell.getValue()
                )[0].event_display_name
              : params.row.getValue("sex") === "M" &&
                params.row.getValue("activity_id") &&
                params.row.getValue("first_event_id") &&
                activities?.male_Activities
                ? activities?.male_Activities
                  .filter(
                    (x) => x.activity_id == params.row.getValue("activity_id")
                  )[0]
                  ?.first_events?.filter(
                    (x) => x.event_id == params.cell.getValue()
                  )[0].event_display_name
                : ""}
          </Typography>
        );
      },
    },
    {
      accessorKey: "first_event_mark",
      header: "Mark",
      size: 85,
      enableGlobalFilter: false,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          // Prevent the number from changing when user is scrolling around the table
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 30) event.target.value = 30;
          if (event.target.value < 0) event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleChange({
              event: {
                name: column.id,
                value: event.target.value,
              },
              id: row.id,
              qual: qual,
              data:
                row.getValue("sex") === "M"
                  ? activities.male_Activities
                  : activities.female_Activities,
              activityTypeCode: "a_as_lvl_activities",
            })
          );

          // Calculate the new mark
          row._valuesCache.overall_mark = calculateMarks(row._valuesCache, {
            payload: { qual: qual },
          });
        };

        return (
          <TextField
            onChange={onChange}
            value={cell.getValue()}
            type="number"
            sx={{
              ...{ ...tableInputStyle },
              width: "100%",
            }}
            disabled={
              isFirstEventMarkDisabled(row)
            }
            placeholder="Mark"
          />
        );
      },
    },
    {
      accessorKey: "second_event_id",
      header: "Second event/skill/ position",
      size: 165,
      enableGlobalFilter: false,
      enableSorting: false,
      editVariant: "select",
      editSelectOptions: (params) => {
        secondEventOptions =
          params.row.getValue("sex") === "F" &&
            params.row.getValue("activity_id") &&
            params.row.getValue("first_event_id") &&
            activities?.female_Activities &&
            activities?.female_Activities
              .filter(
                (x) => x.activity_id == params.row.getValue("activity_id")
              )[0]
              ?.first_events?.filter(
                (x) => x.event_id == params.row.getValue("first_event_id")
              )[0]?.next_level_events
            ? activities?.female_Activities
              .filter(
                (x) => x.activity_id == params.row.getValue("activity_id")
              )[0]
              ?.first_events?.filter(
                (x) => x.event_id == params.row.getValue("first_event_id")
              )[0]
              ?.next_level_events?.filter(
                (x) =>
                  x.event_sex_restriction === "None" ||
                  x.event_sex_restriction == "Female"
              )
              .map((x) => ({
                value: x.event_id,
                label: x.event_display_name,
              }))
            : params.row.getValue("sex") === "M" &&
              params.row.getValue("activity_id") &&
              params.row.getValue("first_event_id") &&
              activities?.male_Activities &&
              activities?.male_Activities
                .filter(
                  (x) => x.activity_id == params.row.getValue("activity_id")
                )[0]
                ?.first_events?.filter(
                  (x) => x.event_id == params.row.getValue("first_event_id")
                )[0]?.next_level_events
              ? activities?.male_Activities
                .filter(
                  (x) => x.activity_id == params.row.getValue("activity_id")
                )[0]
                ?.first_events?.filter(
                  (x) => x.event_id == params.row.getValue("first_event_id")
                )[0]
                ?.next_level_events?.filter(
                  (x) =>
                    x.event_sex_restriction === "None" ||
                    x.event_sex_restriction == "Male"
                )
                .map((x) => ({
                  value: x.event_id,
                  label: x.event_display_name,
                }))
              : [];

        return secondEventOptions;
      },
      muiEditTextFieldProps: (params) => {
        return {
          value:
            secondEventOptions.length === 1
              ? secondEventOptions[0].value
              : params.row.getValue("second_event_id"),
          id: "second_" + params.row.id,
          disabled:
            isSecondEventIdDisabled(params, activities),
          onChange: (event) => {
            onChangeEvent(event.target, params.row, activities, 2);
            // Do the pre-populate after the changeevent as the change event is clearing the values
            prepopulateEvents(params.row, "second_event_id");
          },
        };
      },

      Cell: (params) => {
        return (
          <Typography>
            {params.row.getValue("sex") === "F" &&
              params.row.getValue("activity_id") &&
              params.row.getValue("first_event_id") &&
              params.row.getValue("second_event_id") &&
              activities?.female_Activities
              ? activities?.female_Activities
                .filter(
                  (x) => x.activity_id == params.row.getValue("activity_id")
                )[0]
                ?.first_events?.filter(
                  (x) => x.event_id == params.cell.getValue()
                )[0].event_display_name
              : params.row.getValue("sex") === "M" &&
                params.row.getValue("activity_id") &&
                params.row.getValue("first_event_id") &&
                params.row.getValue("second_event_id") &&
                activities?.male_Activities
                ? activities?.male_Activities
                  .filter(
                    (x) => x.activity_id == params.row.getValue("activity_id")
                  )[0]
                  ?.first_events?.filter(
                    (x) => x.event_id == params.cell.getValue()
                  )[0].event_display_name
                : []}
          </Typography>
        );
      },
    },
    {
      accessorKey: "second_event_mark",
      header: "Mark",
      size: 85,
      enableGlobalFilter: false,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          // Prevent the number from changing when user is scrolling around the table
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 30) event.target.value = 30;
          if (event.target.value < 0) event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleChange({
              event: {
                name: column.id,
                value: event.target.value,
              },
              id: row.id,
              qual: qual,
              data:
                row.getValue("sex") === "M"
                  ? activities.male_Activities
                  : activities.female_Activities,
              activityTypeCode: "a_as_lvl_activities",
            })
          );

          // Calculate the new mark
          row._valuesCache.overall_mark = calculateMarks(row._valuesCache, {
            payload: { qual: qual },
          });
        };

        return (
          <TextField
            onChange={onChange}
            value={cell.getValue()}
            type="number"
            sx={{
              ...{ ...tableInputStyle },
              width: "100%",
            }}
            disabled={
              isSecondEventMarkDisabled(row, activities)
            }
            placeholder="Mark"
          />
        );
      },
    },
    {
      accessorKey: "third_event_id",
      header: "Third event/skill/ position",
      size: 165,
      enableGlobalFilter: false,
      enableSorting: false,
      editVariant: "select",
      editSelectOptions: (params) => {
        thirdEventOptions =
          params.row.getValue("sex") === "F" &&
            params.row.getValue("activity_id") &&
            params.row.getValue("first_event_id") &&
            params.row.getValue("second_event_id") &&
            activities?.female_Activities &&
            activities?.female_Activities
              .filter(
                (x) => x.activity_id == params.row.getValue("activity_id")
              )[0]
              ?.first_events?.filter(
                (x) => x.event_id == params.row.getValue("first_event_id")
              )[0]?.next_level_events &&
            activities?.female_Activities
              .filter(
                (x) => x.activity_id == params.row.getValue("activity_id")
              )[0]
              ?.first_events?.filter(
                (x) => x.event_id == params.row.getValue("first_event_id")
              )[0]
              ?.next_level_events.filter(
                (x) => x.event_id == params.row.getValue("second_event_id")
              )[0]?.next_level_events
            ? activities?.female_Activities
              .filter(
                (x) => x.activity_id == params.row.getValue("activity_id")
              )[0]
              ?.first_events?.filter(
                (x) => x.event_id == params.row.getValue("first_event_id")
              )[0]
              ?.next_level_events.filter(
                (x) => x.event_id == params.row.getValue("second_event_id")
              )[0]
              ?.next_level_events?.filter(
                (x) =>
                  x.event_sex_restriction === "None" ||
                  x.event_sex_restriction == "Female"
              )
              .map((x) => ({
                value: x.event_id,
                label: x.event_display_name,
              }))
            : params.row.getValue("sex") === "M" &&
              params.row.getValue("activity_id") &&
              params.row.getValue("first_event_id") &&
              params.row.getValue("second_event_id") &&
              activities?.male_Activities &&
              activities?.male_Activities
                .filter(
                  (x) => x.activity_id == params.row.getValue("activity_id")
                )[0]
                ?.first_events?.filter(
                  (x) => x.event_id == params.row.getValue("first_event_id")
                )[0]?.next_level_events &&
              activities?.male_Activities
                .filter(
                  (x) => x.activity_id == params.row.getValue("activity_id")
                )[0]
                ?.first_events?.filter(
                  (x) => x.event_id == params.row.getValue("first_event_id")
                )[0]
                ?.next_level_events.filter(
                  (x) => x.event_id == params.row.getValue("second_event_id")
                )[0]?.next_level_events
              ? activities?.male_Activities
                .filter(
                  (x) => x.activity_id == params.row.getValue("activity_id")
                )[0]
                ?.first_events?.filter(
                  (x) => x.event_id == params.row.getValue("first_event_id")
                )[0]
                ?.next_level_events.filter(
                  (x) => x.event_id == params.row.getValue("second_event_id")
                )[0]
                ?.next_level_events?.filter(
                  (x) =>
                    x.event_sex_restriction === "None" ||
                    x.event_sex_restriction == "Male"
                )
                .map((x) => ({
                  value: x.event_id,
                  label: x.event_display_name,
                }))
              : [];
        return thirdEventOptions;
      },
      muiEditTextFieldProps: (params) => {
        return {
          id: "third_" + params.row.id,
          value:
            thirdEventOptions.length === 1
              ? thirdEventOptions[0].value
              : params.row.getValue("third_event_id"),
          disabled:
            isThirdEventIdDisabled(params, activities),
          onChange: (event) => {
            data[params.row.index].third_event_id = event.target.value;

            // Clear mark fields from table
            resetMarks(params.row, 3);

            dispatch(
              handleChange({
                event: {
                  name: event.target.name,
                  value: event.target.value,
                },
                id: params.row.id,
                qual: qual,
                data:
                  params.row.getValue("sex") === "M"
                    ? activities.male_Activities
                    : activities.female_Activities,
                activityTypeCode: "a_as_lvl_activities",
              })
            );
          },
        };
      },

      Cell: (params) => {
        return (
          <Typography>
            {params.row.getValue("sex") === "F" &&
              params.row.getValue("activity_id") &&
              params.row.getValue("first_event_id") &&
              params.row.getValue("second_event_id") &&
              activities?.female_Activities
              ? activities?.female_Activities
                .filter(
                  (x) => x.activity_id == params.row.getValue("activity_id")
                )[0]
                ?.first_events?.filter(
                  (x) => x.event_id == params.cell.getValue()
                )[0].event_display_name
              : params.row.getValue("sex") === "M" &&
                params.row.getValue("activity_id") &&
                params.row.getValue("first_event_id") &&
                params.row.getValue("second_event_id") &&
                activities?.male_Activities
                ? activities?.male_Activities
                  .filter(
                    (x) => x.activity_id == params.row.getValue("activity_id")
                  )[0]
                  ?.first_events?.filter(
                    (x) => x.event_id == params.cell.getValue()
                  )[0].event_display_name
                : []}
          </Typography>
        );
      },
    },
    {
      accessorKey: "third_event_mark",
      header: "Mark",
      size: 85,
      enableGlobalFilter: false,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          // Prevent the number from changing when user is scrolling around the table
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 30) event.target.value = 30;
          if (event.target.value < 0) event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleChange({
              event: {
                name: column.id,
                value: event.target.value,
              },
              id: row.id,
              qual: qual,
              data:
                row.getValue("sex") === "M"
                  ? activities.male_Activities
                  : activities.female_Activities,
              activityTypeCode: "a_as_lvl_activities",
            })
          );

          // Calculate the new mark
          row._valuesCache.overall_mark = calculateMarks(row._valuesCache, {
            payload: { qual: qual },
          });
        };

        return (
          <TextField
            onChange={onChange}
            value={cell.getValue()}
            type="number"
            sx={{
              ...{ ...tableInputStyle },
              width: "100%",
            }}
            disabled={
              isThirdEventMarkDisabled(row, activities)
            }
            placeholder="Mark"
          />
        );
      },
    },
    {
      accessorKey: "overall_mark",
      header: "Overall activity mark",
      size: 140,
      enableGlobalFilter: false,
      enableSorting: false,
      enableEditing: false,
      Cell: (params) => {
        return (
          <Typography>
            {params.row.original.candidate_attendance_status === "W"
              ? "Withdrawn"
              : params.row.original.candidate_attendance_status === "A"
                ? "Not Available"
                : params.cell.getValue()}
          </Typography>
        );
      },
    },
    {
      accessorKey: "onsite_filmed_evidence_available_value",
      header: "Filmed as part of the on-site sample",
      size: 150,
      enableGlobalFilter: false,
      enableSorting: false,
      editVariant: "select",
      editSelectOptions: [
        { value: "Y", label: "Yes" },
        { value: "N", label: "No" },
      ],
      muiEditTextFieldProps: (params) => {
        return {
          disabled: isFirstEventMarkDisabled(params.row, activities) ||
            (params.row.original.candidate_attendance_status === "W" ||
            params.row.original.candidate_attendance_status === "A"),
          value: params.row.original.onsite_filmed_evidence_available_value || "N",
          onChange: (event) => {
            data[params.row.index].onsite_filmed_evidence_available_value =
              event.target.value;
  
            dispatch(
              handleChange({
                event: {
                  name: event.target.name,
                  value: event.target.value,
                },
                id: params.row.id,
                qual: qual,
                data:
                  params.row.getValue("sex") === "M"
                    ? activities.male_Activities
                    : activities.female_Activities,
                activityTypeCode: "a_as_lvl_activities",
              })
            );
          },
        }
      },

      Cell: (params) => {
        const val = params.row.original.onsite_filmed_evidence_available_value || "N";
        return (
          <Select
            sx={{ ...tableInputStyle, width: "100%" }}
            disabled={
              !(params.row.original.onsite_filmed_evidence_available === "Y")
            }
            variant={
              !(params.row.original.onsite_filmed_evidence_available === "Y")
                ? "filled"
                : "outlined"
            }
            name="onsite_filmed_evidence_available_value"
            value={val}
            onChange={(e) =>
              dispatch(
                handleChange({
                  event: { name: e.target.name, value: e.target.value },
                  id: params.row.id,
                  qual: qual,
                  data:
                    params.row.getValue("sex") === "M"
                      ? activities.male_Activities
                      : activities.female_Activities,
                  activityTypeCode: "a_as_lvl_activities",
                })
              )
            }
          >
            <MenuItem value="N">No</MenuItem>
            <MenuItem value="Y">Yes</MenuItem>
          </Select>
        );
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    // Implement custom filter fn to restrict searching to candidate_number and candidate_name only
    filterFns: {
      numberandnameonlyfn: (row, id, filterValue) =>
        id === "candidate_number" || id === "candidate_name"
          ? row.getValue(id).indexOf(filterValue.toUpperCase()) >= 0
          : false,
    },
    globalFilterFn: "numberandnameonlyfn",
    enableColumnPinning: false,
    enableColumnActions: false,
    enableColumnResizing: true,
    enableColumnFilters: false,
    enableEditing: true,
    enableHiding: false,
    enableKeyboardShortcuts: false, // With our custom UI elements, set this to false to prevent underlying table cells getting focus.
    editDisplayMode: "table",
    layoutMode: "grid-no-grow", //constant column widths
    initialState: {
      sorting: [{ id: "candidate_number", desc: false }],
      columnPinning: { left: ["candidate_number", "candidate_name", "sex"] },
      density: "compact",
    },
    muiTableProps: {
      className: "AASLevelTable",
    },
    muiTableBodyRowProps: ({ row }) => {
      return (
        row.original.candidate_attendance_status === "W" ||
          row.original.candidate_attendance_status === "A"
          ? {
            //conditionally style expanded rows
            sx: {
              backgroundColor: "#ccc",
            },
          }
          : {},
        { hover: false }
      );
    },
    muiTableHeadProps: {
      sx: {
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: "#8884b4",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#8884b4 ",
        "&:before": {
          backgroundColor: "#8884b4 !important",
        },
      },
    },
    muiTableBodyCellProps: { sx: { paddingLeft: "5px", padding: 0 } },
    muiTableBodyProps: {
      ref: tableRef,
      sx: {
        maxHeight: "200px",
        //  overflowY: "auto",
      },
    },
  });
  return (
    <>
      <MaterialReactTable table={table} />
      {/* <DataGrid
        sx={{
          "& .MuiDataGrid-cellContent": {
            textWrap: "wrap",
          },
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        autoHeight
        checkboxSelection={false}
        onSelectionModelChange={() => {}}
        disableColumnMenu
      /> */}
    </>
  );
}
