import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const fetchRole = createAsyncThunk(
  "roles/fetchData",
  async ({qual, token}) => {
    const response = await axios.get(
      `${process.env.REACT_APP_MARK_BASEURL}` +
        `markssubmissions/getroles?qualification_id=${qual}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);
const roleSlice = createSlice({
  name: "roles",
  initialState: {
    roles: [],
    status: "idle",
    error: null,
  },
  reducers: {
    resetRole: state => {
      state.roles = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRole.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = action.payload.candidate_roles;
      })
      .addCase(fetchRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { resetRole } = roleSlice.actions;
export const selectRole = state => state.roles;
export default roleSlice;
