import { Routes, Route, useSearchParams } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import VafForm from "./components/pages/VafForm";
import LandingPage from "./components/pages/LandingPage";
import PageNotFound from "./components/pages/PageNotFound";
import UnAuthorizedAccess from "./components/pages/UnAuthorizedAccess";
import { useContext } from "react";
import { UserContext } from "./context/UserContext";
import MarksSubmissionForm from "./components/pages/MarksSubmissionForm";
import { MarkSubmissionView } from "./components/markSubmissionSteps/MarkSubmissionView";

export const AppRoute = () => {
  const { roles, businessStream, loading } = useContext(UserContext);

  const checkUserHasAllowedRolesAndStreams = () => {
    return (
      (roles.includes("EXAMS_OFFICER") ||
        roles.includes("PE_SUBMISSIONS_ACCESS")) &&
      (businessStream.includes("OCR") || businessStream[0] === "OCR")
    );
  };

  let [searchParams, setSearchParams] = useSearchParams();

  const isVisitArrangementAllowed = () => {
    // Hack to allow our teams see the other areas out of the limited dates
    const todaysDate = searchParams.get("testdate") && !isNaN(Date.parse(searchParams.get("testdate"))) ? new Date(searchParams.get("testdate")) : new Date();

    const startDate = new Date(todaysDate.getFullYear(), 0, 0);
    const endDate = new Date(todaysDate.getFullYear(), 5, 30);
    return todaysDate >= startDate && todaysDate < endDate;
  }

  if (loading) {
    return <></>;
  }
  return (
    <>
      <Routes>
        {checkUserHasAllowedRolesAndStreams() ? (
          <>
            <Route exact path="/" element={<HomePage />} />
            {isVisitArrangementAllowed() ? (<Route exact path="/home" element={<LandingPage title="Visit arrangements" />} />) : (<Route exact path="/*" element={<UnAuthorizedAccess />} />)}
            <Route exact path="/home/markSubmission" element={<LandingPage title="Marks submission" />} />
            <Route exact path="/home/vaf" element={<VafForm />} />
            <Route exact path="/home/markSubmission/form" element={<MarksSubmissionForm />} />
            <Route exact path="/home/markSubmission/view" element={<MarkSubmissionView />} />
            <Route exact path="/*" element={<PageNotFound />} />
          </>
        ) : (
          <Route exact path="/*" element={<UnAuthorizedAccess />} />
        )}
      </Routes>
    </>
  );
};
