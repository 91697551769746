import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const fetchRestrictedActivities = createAsyncThunk(
  "candidates/fetchRestrictedActivities",
  async ({ activity_id, qual, id, type, token }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_MARK_BASEURL}` +
        `markssubmissions/getrestrictedactivities?activity_id=${activity_id}&qualification_id=${qual}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return {
      restrictedActivityResponse: response.data,
      id: id,
      activity_id,
      type,
    };
    // return response.data;
  }
);

const restrictedActivitySlice = createSlice({
  name: "restrictedActivityData",
  initialState: {},
  reducers: {
    resetRestrictedActivity: state => (state = {}),
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRestrictedActivities.pending, state => {
        // state.restrictedActivityData[action.payload.id] = "loading";
      })
      .addCase(fetchRestrictedActivities.fulfilled, (state, action) => {
        const { id, type, activity_id, restrictedActivityResponse } =
          action.payload;
        const restrictedActivityArray =
          restrictedActivityResponse.restricted_activities
            ? restrictedActivityResponse.restricted_activities.map(item =>
                parseInt(item.restricted_activity_id, 10)
              )
            : [];
        restrictedActivityArray.push(activity_id);
        if (!state[id]) {
          state[id] = {};
        }
        if (!state[id][type]) {
          state[id][type] = {};
        }
        state[id][type] = restrictedActivityArray;
      })
      .addCase(fetchRestrictedActivities.rejected, (state, action) => {
        // state.status = "failed";
        // state.error = action.error.message;
      });
  },
});
export const {resetRestrictedActivity} =restrictedActivitySlice.actions;
export const selectRestrictedActivity = state => state.restrictedActivityData;
export default restrictedActivitySlice;
