import React, { memo, useContext, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button, MenuItem, Select, Divider } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";  
import { MainContext } from "../../context/Context";
import { ReactComponent as Logo } from "../../assets/ocr_logo.svg";

function Header() {
  const { user, centre_name, centre_number, setCentreNumber } = useContext(UserContext);
  const { handleCenterDetails } = useContext(MainContext);
  const navigate = useNavigate();
  const location = useLocation();  // Use the useLocation hook to get the current route

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.assign(
      `${process.env.REACT_APP_MY_CAMBRIDGE_LOGOUT_LINK}?redirect_uri=${process.env.REACT_APP_IDENTITY_LOGOUT_REDIRECT_URL}&client_id=${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
    );
  };

  const getCenterId = center =>
    center?.sourceSystems?.length > 0
      ? center?.sourceSystems[0]?.orgId
      : center?.bpid;

  const renderCenterList = () => {
    return user?.orgs?.map(center => (
      <MenuItem key={center.name} value={getCenterId(center)}>
        {getCenterId(center)} - {center.name}
      </MenuItem>
    ));
  };

  useEffect(() => {
    handleCenterDetails(centre_name, centre_number);
  }, [centre_name, centre_number]);

  const handleDuelLogic = e => {
    setCentreNumber(e.target.value);
    navigate("/");
  };

  return (
    <AppBar position="static">
      <Toolbar
        variant="dense"
        sx={{ display: "flex", backgroundColor: "lightgray", p: 1 }}>
        <Logo style={{ width: "70px", height: "50px" }} />
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ mx: 2 }}
        />
        <Typography variant="h6" color="black" sx={{ flexGrow: 1 }}>
          OCR PE Moderation Service
        </Typography>

        <Select
          value={centre_number}
          onChange={e => handleDuelLogic(e)}
          name={centre_name}
          style={{ width: "25%" }}>
          {renderCenterList()}
        </Select>

        {/* Conditionally render buttons based on the current route */}
        {location.pathname !== "/" && (
          <>
            <Button variant="outlined" style={{ color: 'black' }} onClick={handleLogout} sx={{ ml: 1, mr: 1 }}>
              Sign out
            </Button>
            <Button
              variant="outlined"
              style={{ color: 'black' }}
              onClick={() => {
                window.location.replace(
                  process.env.REACT_APP_MY_CAMBRIDGE_HOMEPAGE
                );
              }}>
              Go to My Cambridge
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default memo(Header);
