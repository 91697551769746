import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const fetchExceptionalActivity = createAsyncThunk(
  "exceptionActivity/fetchData",
  async ({ qual, token }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_MARK_BASEURL}` +
        `markssubmissions/activitiesnoevents?qualification_id=${qual}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);
const exceptionActivitySlice = createSlice({
  name: "exceptionActivity",
  initialState: {
    noEvents: [],
    status: "idle",
    error: null,
  },
  reducers: {
    exceptionActivityReset: state => {
      state.noEvents = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchExceptionalActivity.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchExceptionalActivity.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.noEvents = action.payload;
      })
      .addCase(fetchExceptionalActivity.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { exceptionActivityReset } = exceptionActivitySlice.actions;
export const selectExceptionActivity = state => state.exceptionActivity;
export default exceptionActivitySlice;
