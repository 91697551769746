import React from "react";
import { Typography, Box, Grid, Container, Link } from "@mui/material";
import svg from "../../assets/PageNotFound.svg";

const PageNotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh",
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={12} md={6}>
            <Typography variant="h6" sx={{ mt: 15, mb: 1 }}>
              The page you’re looking for doesn’t exist, please contact{" "}
              {/* <Link
                href="https://support.ocr.org.uk/"
                color="#0000e8"
                underline="always"
              >
                {"support@ocr.org.uk"}{" "}
              </Link> */}
              <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
            </Typography>
          </Grid>
          <Grid xs={12} md={6}>
            <img src={svg} alt="" sx={{ width: "100%", height: "21rem" }} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PageNotFound;
