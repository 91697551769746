import React, { useEffect } from "react";
import { Outlet, Link, useSearchParams } from "react-router-dom";
import { Typography, Grid, Button, Divider, Link as MLink } from "@mui/material";

import { BannerImage } from "../common/BannerImage";
import { useDispatch } from "react-redux";
import { resetCandidates } from "../../store/reducers/candidateReducer";
import { resetActivity } from "../../store/reducers/activityReducer";
import { resetRole } from "../../store/reducers/roleReducer";
import { resetPemifData } from "../../store/reducers/pemifReducer";
import { LandingPageButtons } from "../common/LandingPageButtons";

function HomePage() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(resetCandidates());
    dispatch(resetActivity());
    dispatch(resetRole());
    dispatch(resetPemifData())
  }, []);

  let [searchParams, setSearchParams] = useSearchParams();

  const isVisitArrangementAllowed = () => {
    // Hack to allow our teams see the other areas out of the limited dates
    const todaysDate = searchParams.get("testdate") && !isNaN(Date.parse(searchParams.get("testdate"))) ? new Date(searchParams.get("testdate")) : new Date();

    const startDate = new Date(todaysDate.getFullYear(), 0, 0);
    const endDate = new Date(todaysDate.getFullYear(), 5, 30);
    return todaysDate >= startDate && todaysDate < endDate;
  }

  const isMarksSubmissionAllowed = () => {
    // Hack to allow our teams see the other areas out of the limited dates
    const todaysDate = searchParams.get("testdate") && !isNaN(Date.parse(searchParams.get("testdate"))) ? new Date(searchParams.get("testdate")) : new Date();

    const startDate = new Date(todaysDate.getFullYear(), 1, 1);
    const endDate = new Date(todaysDate.getFullYear(), 9, 21);

    return todaysDate >= startDate && todaysDate < endDate;
  }

  const isEvidenceSubmissionAllowed = () => {

    // As per CASS-836 evidence submission is available all the time
    return true;


    // Hack to allow our teams see the other areas out of the limited dates
    /*const todaysDate = searchParams.get("testdate") && !isNaN(Date.parse(searchParams.get("testdate"))) ? new Date(searchParams.get("testdate")) : new Date();

    const startDate = new Date(todaysDate.getFullYear(), 1, 1);
    const endDate = new Date(todaysDate.getFullYear(), 9, 21);

    return todaysDate >= startDate && todaysDate < endDate;
    */
  }

  const checkMarkSubmissionDisabled = () => {
    const todaysDate = searchParams.get("testdate") && !isNaN(Date.parse(searchParams.get("testdate"))) ? new Date(searchParams.get("testdate")) : new Date();
    const startDate = new Date(2025, 1, 1)
    const endDate = new Date(2025, 7, 22);
    return false
    // Go to Mark Submission button needs to be enabled now for working purpose
    // Below needs to be enabled once the dev testing done
    // return ( todaysDate <= startDate || todaysDate >= endDate)
  }
  const isMarkSubmissionDisabled = checkMarkSubmissionDisabled()
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ width: "100%", Height: "100%", margin: "0px" }}
      >
        <BannerImage />


        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
          }}
        >

          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontSize:"20px !important",
               textAlign:"center",
                paddingTop: "2rem",
              }}
            >
              OCR PE Moderation Service
            </Typography>
            <Divider
              sx={{ width: "30%", alignSelf: "center", marginTop: "1rem" }}
            />
          </Grid>


          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              my: 4,
              mx: 1
            }}
          >
            {isVisitArrangementAllowed() ? (
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography variant="h6" sx={{ mb: 2, fontSize: '16px !important' , fontWeight: 'bold'}}>
                  Visit arrangements
                </Typography>
                <Typography variant="caption" sx={{ mb: 2 }}>
                  Use this service to submit visit arrangement details. You can
                  save and return to this form until you submit it to us. Please submit on or before 21 February.
                </Typography>
                <Typography variant="caption" sx={{mb: 2}}>
                If you need help when completing the form, or for any queries or
                amendments after you have submitted the form, please contact{" "}
                <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>.
                </Typography>
                <Typography variant="caption" sx={{mb: 1}}>
                 You will be able to access this form from 1 February.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: "fit-content", mt: 2 }}
                  component={Link}
                  to="/home"
                >
                  Go to visit arrangements
                </Button>
              </Grid>
            ) : (<div />)}
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography variant="h6" sx={{ mb: 2, fontSize: '16px !important' , fontWeight: 'bold'}}>
                  Marks submission
                </Typography>
                <Typography variant="caption" sx={{ mb: 2 }}>
                  Use this service to submit marks for practical components only. You can save and
                  return to this form until you submit it to us.
                </Typography>
                <Typography variant="caption" sx={{mb: 2}}>
                If you need help when completing the form, or for any queries or
                amendments after you have submitted the form, please contact{" "}
                <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>.
                </Typography>
                <Typography variant="caption" sx={{mb: 1}}>
                 You will be able to access this form from 1 February.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: "fit-content", mt: 2, backgroundColor: isMarkSubmissionDisabled ?'darkgray !important': '', cursor:isMarkSubmissionDisabled?'not-allowed': 'pointer'}}
                  component={Link}
                  disabled={isMarkSubmissionDisabled}
                  to="/home/markSubmission"
                >
                  Go to Marks submission
                </Button>
                <Outlet />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography variant="h6" sx={{ mb:2,fontSize: '16px !important' , fontWeight: 'bold' }}>
                  Evidence submission
                </Typography>
                <Typography variant="caption" sx={{ mb: 2 }}>
                Use this service to submit activity evidence. You can save and
                  return to this form until you submit it to us.
                </Typography>
                <Typography variant="caption" sx={{mb: 2}}>
                If you need help when completing the form, or for any queries or
                amendments after you have submitted the form, please contact{" "}
                <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>.
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: "fit-content", mt: {xs: 2,sm:11,md:8, lg:5 }}}
                  component={Link}
                  to={process.env.REACT_APP_SFA_URL}
                >
                  Go to Evidence Submission
                </Button>
                <Outlet />
              </Grid>
          </Grid>

        </Grid>
          <LandingPageButtons />
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Divider
            sx={{ width: "30%", alignSelf: "center" }}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default HomePage;
