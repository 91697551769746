import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
export const DateComponent = ({value,handleChange,name,dataset,error,helperText,min,max}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        format="dd/MM/yyyy"
        value={value}
        onChange={date =>
          handleChange({
            target: {
              type: "date",
              name: name,
              value: date,
              dataset: dataset,
            },
          })
        }
        minDate={new Date(min)}
        maxDate={new Date(max)}
        slotProps={{
          textField: {
            error: error,
            helperText: helperText,
            readOnly: true,
          },
        }}
      />
    </LocalizationProvider>
  );
};
