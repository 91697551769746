import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';



export const useRequest = (config) => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (requestConfig) => {
      try {
        setLoading(true);
        setResult(null);
        setError(null);
        const response = await axios.request({
          ...requestConfig,
          // There is a bug in axios that prevents brackets from being encoded,
          // remove the paramsSerializer when fixed.
          // https://github.com/axios/axios/issues/3316
          paramsSerializer: (params) => {
            return queryString.stringify(params, { arrayFormat: 'bracket' });
          },
        });
        setResult(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    config && fetchData(config);
  }, []);

  return useMemo(() => {
    if (result) return { result, error: null, loading: false };
    if (error) return { result: null, error, loading: false };
    if (loading) return { result: null, error: null, loading };
    return { result: null, error: null, loading: false };
  }, [result, error, loading]);
};
