import { useMemo } from 'react';
import { useRequest } from './request-hook';
import { useAuth } from "react-oidc-context";
const baseURL = "";

export const useAPIRequest =(input) => {
  return useRequest(useMemo(() => input && { ...input, baseURL }, [input]));
};

export const useAuthRequest =(input) => {
  const auth = useAuth();
  const accessToken = auth.user?.access_token;

  return useAPIRequest(
    useMemo(
      () => input && accessToken && { ...input, headers: { ...input.headers, Authorization: `Bearer ${accessToken}` } },
      [input, accessToken],
    ),
  );
};

