import { createTheme } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: {
      light: blueGrey[300],
      main: "#435A60",
      dark: blueGrey[700],
      darker: blueGrey[900],
    },
    secondary: {
      main: "#02029a",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { fontSize: "0.7rem" },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "#91969b",

          "&.Mui-completed": {
            color: "#090909",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: "2rem",
          marginTop: "0px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "2rem",
          marginTop: "0px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textWrap: "nowrap",
        },
      },
    },
  },
});

export const inputStyle = {
  "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select":
    {
      whiteSpace: "wrap",
      lineHeight: 1,
      width: "100%",
      paddingLeft: "5px",
      fontSize: "0.7rem !important",
    },

  "& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
    padding: "0px 0px 0px 12px !important",
  },
  "& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
    padding: "0px 0px 0px 12px !important",
    whiteSpace: "wrap",
    lineHeight: 1,
    width: "100%",
    fontSize: "0.7rem !important",
  },
};

export const tableInputStyle = {
  marginTop:"0px",
  width:"100%",
  borderRadius:"0px",
  "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select":
    {
      whiteSpace: "wrap",
      lineHeight: 1,
      width: "100%",
      paddingLeft: "5px",
      paddingTop: "20px",
      border:0,
      fontSize: "0.7rem !important",
      boxShadow: "none",
      marginTop:"0px",
      borderRadius:"0px"

    },

  "& .MuiSelect-select":
  {
      marginTop:"0px",

  },

  "& .MuiOutlinedInput-notchedOutline":
  {
    border: 0
  },

  "& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
    padding: "0px 0px 0px 5px !important",
  },
  "& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
    padding: "0px 0px 0px 15px !important",
    whiteSpace: "wrap",
    lineHeight: 1,
    width: "100%",
    fontSize: "0.8rem !important",
    marginTop: "0px"
  },

  "& .MuiInputAdornment-root": {
    width:"15px"
  }

};

export const inputStyleSecondary = {
  "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select":
    {
      whiteSpace: "wrap",
      lineHeight: 1,
      width: "100%",
      paddingLeft: "5px",
      fontSize: "0.7rem !important",
    },

  "& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
    padding: "0px 0px 0px 12px !important",
  },
  "& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
    padding: "0px 0px 0px 12px !important",
    whiteSpace: "wrap",
    lineHeight: 1,
    width: "100%",
    fontSize: "1rem !important",
    fontWeight: "bold !important",
    color: "#00000099 !important",
    "-webkit-text-fill-color": "#00000099 !important",
  },
  "& .MuiTypography-root": {
    fontSize: "1rem !important",
    fontWeight: "bold !important",
    color: "#00000099",
  },
};

export const tableInputStyleSecondary = {
  marginTop:"0px",
  width:"100%",
  borderRadius:"0px",
  "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select":
    {
      whiteSpace: "wrap",
      lineHeight: 1,
      width: "100%",
      paddingLeft: "5px",
      paddingTop: "20px",
      border:0,
      fontSize: "0.7rem !important",
      boxShadow: "none",
      marginTop:"0px",
      borderRadius:"0px"
    },

  "& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
    padding: "0px 0px 0px 12px !important",
  },
  "& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
    padding: "0px 0px 0px 12px !important",
    whiteSpace: "wrap",
    lineHeight: 1,
    width: "100%",
    fontSize: "1rem !important",
    fontWeight: "bold !important",
    color: "#00000099 !important",
    "-webkit-text-fill-color": "#00000099 !important",
  },
  "& .MuiTypography-root": {
    fontSize: "1rem !important",
    fontWeight: "bold !important",
    color: "#00000099",
  },
};

export const disabledColumnValue = {
  color: "#00000099 !important",
  backgroundColor: "rgba(0, 0, 0, 0.08) !important",
  padding:'0px',
  height:'100%',
  alignContent: 'center'
}

export const disabledOverallMarkColumnValue = {
  color: "#00000099 !important",
  backgroundColor: "rgba(0, 0, 0, 0.12) !important",
  padding:'0px',
  height:'100%',
  paddingTop:'7px'
}
