import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const fetchActivities = createAsyncThunk(
  "activities/fetchData",
  async ({qual, token}) => {

    const response = await axios.get(
      `${process.env.REACT_APP_MARK_BASEURL}` +
        `markssubmissions/getactivitiesandevents?qualification_id=${qual}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);
const activitySlice = createSlice({
  name: "activities",
  initialState: {
    activities: [],
    status: "idle",
    error: null,
  },
  reducers: {
    resetActivity: state => {
      state.activities = [];
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchActivities.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.activities = action.payload.activities;
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { resetActivity } = activitySlice.actions;
export const selectActivity = state => state.activity;
export default activitySlice;
