import { useLocation, useNavigate } from "react-router-dom";
import { CommonHeaderDetails } from "./CommonHeaderDetails";
import { useSelector } from "react-redux";
import { selectCandidates } from "../../store/reducers/candidateReducer";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Container, Typography } from "@mui/material";
import { QualMapping } from "../../utils/constants";
import { checkStatus } from "./StepTwo/commonFuntions";
const RenderMultipleRows = ({ value, name }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {value.map((activity, index) => (
        <Typography
          key={index}
          variant="body2"
          sx={{ mb: 2, textWrap: "wrap", lineHeight: 1 }}
        >
          {![
            "filmed_evidence_to_be_provided",
            "onsite_filmed_evidence_available_value",
            "activity_type",
          ].includes(name)
            ? activity[name]
            : name === "filmed_evidence_to_be_provided" ||
              name === "onsite_filmed_evidence_available_value"
            ? activity[name] === "Y"
              ? "Yes"
              : "No"
            : name === "activity_type" && activity}
        </Typography>
      ))}
    </div>
  );
};
const RenderMarksRows = ({ value, name }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {value.map((activity, index) =>
        activity?.candidate_attendance_status === "P" ||
        activity?.candidate_attendance_status === "W" ||
        name !== "total_for_activity" ? (
          <Typography
            key={index}
            variant="body2"
            sx={{
              mb: 2.5,
              textWrap: "wrap",
              lineHeight: 1,
              fontWeight: name === "total_for_activity" ? "bold" : "normal",
            }}
          >
            {activity[name] ? `${activity[name]}/20` : `0/20`}
          </Typography>
        ) : (
          <Typography
            key={index}
            variant="body2"
            sx={{ mb: 2.5, textWrap: "wrap", lineHeight: 1 }}
          >
            Not available
          </Typography>
        )
      )}
    </div>
  );
};
const RenderAlevelCell = ({ row, name }) => {
  if (
    ["first_event_mark", "second_event_mark", "third_event_mark"].includes(name)
  ) {
    return (
      <Typography sx={{ textWrap: "wrap" }}>
        {row.a_as_lvl_activities[name]
          ? `${row.a_as_lvl_activities[name]}/30`
          : `0/30`}
      </Typography>
    );
  } else
    return (
      <Typography sx={{ textWrap: "wrap" }}>
        {row.a_as_lvl_activities[name]}
      </Typography>
    );
};
export const MarkSubmissionView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { candidates } = useSelector(selectCandidates);
  let height = 80;
  const columns = [
    {
      field: "candidate_number",
      sortable: false,
      headerName: "Candidate number",
      width: 80,
    },
    {
      field: "candidate_name",
      sortable: false,
      headerName: "Candidate name",
      width: 100,
    },
    {
      field: "sex",
      headerName: "Sex assigned at birth",
      width: 110,
      sortable: false,
      renderCell: params => {
        return (
          <Typography>
            {params.value === "M"
              ? "Male" : "Female"}
              {/* // : params.value === "F"
              // ? "Female"
              // : "Unknown"} */}
          </Typography>
        );
      },
    },
    {
      field: "id",
      headerName: "Type of activity",
      width: 110,
      sortable: false,
      renderCell: ({ value }) => (
        <RenderMultipleRows
          value={
            location.state?.name === QualMapping.GCSE
              ? ["Team", "Individual", "Free choice 1"]
              : [
                  "Team",
                  "Individual",
                  "Free choice 1",
                  "Free choice 2",
                  "Analysing performance",
                ]
          }
          name="activity_type"
        />
      ),
    },
    {
      field: "activityRows",
      headerName: "Activities",
      width: 120,
      sortable: false,
      renderCell: ({ value }) => (
        <RenderMultipleRows value={value} name="activity_name" />
      ),
    },
    {
      field: "firstEventRows",
      headerName: "First event/ skill/ position",
      width: 120,
      sortable: false,
      renderCell: ({ value }) => (
        <RenderMultipleRows value={value} name="first_event_name" />
      ),
    },
    {
      field: "firstEventMarkRows",
      headerName: "Mark",
      width: 85,
      sortable: false,
      renderCell: params => (
        <RenderMarksRows value={params.value} name="first_event_mark" />
      ),
    },
    {
      field: "secondEventRows",
      headerName: "Second event/ skill/ position",
      width: 120,
      sortable: false,
      renderCell: ({ value }) => (
        <RenderMultipleRows value={value} name="second_event_name" />
      ),
    },
    {
      field: "secondEventMarkRows",
      headerName: "Mark",
      width: 85,
      sortable: false,
      renderCell: ({ value }) => (
        <RenderMarksRows value={value} name="second_event_mark" />
      ),
    },
    {
      field: "thirdEventRows",
      headerName: "Third event/ skill/ position",
      width: 120,
      sortable: false,
      renderCell: ({ value }) => (
        <RenderMultipleRows value={value} name="third_event_name" />
      ),
    },
    {
      field: "thirdEventMarkRows",
      headerName: "Mark",
      width: 85,
      sortable: false,
      renderCell: ({ value }) => (
        <RenderMarksRows value={value} name="third_event_mark" />
      ),
    },
    {
      field: "totalMarkRows",
      headerName: "Total activity mark",
      width: 90,
      sortable: false,
      renderCell: ({ value }) => (
        <RenderMarksRows value={value} name="total_for_activity" />
      ),
    },
    {
      field: "overall_mark",
      headerName: "Overall activity mark",
      width: 85,
      sortable: false,
      renderCell: params => {
        if (location.state?.name === QualMapping.GCSE) {
          if (checkStatus(params.row, "gcse") === "Present") {
            return (
              <Typography sx={{ fontWeight: "bold" }}>
                {params.value ? `${params.value}/60` : `0/60`}
              </Typography>
            );
          } else {
            return <Typography>{checkStatus(params.row, "gcse")}</Typography>;
          }
        } else if (location.state?.name === QualMapping.ENTRY) {
          if (checkStatus(params.row, "entry") === "Present") {
            return (
              <Typography sx={{ fontWeight: "bold" }}>
                {params.value ? `${params.value}/100` : `0/100`}
              </Typography>
            );
          } else {
            return <Typography>{checkStatus(params.row, "entry")}</Typography>;
          }
        }
      },
    },
  ];

  const columnsAlevel = [
    {
      field: "candidate_number",
      sortable: false,
      headerName: "Candidate number",
      width: 80,
    },
    {
      field: "candidate_name",
      sortable: false,
      headerName: "Candidate name",
      width: 100,
    },
    {
      field: "sex",
      headerName: "Sex assigned at birth",
      width: 110,
      sortable: false,
      renderCell: params => {
        return (
          <Typography>
            {params.value === "M"
              ? "Male" : "Female"}
              {/* // : params.value === "F"
              // ? "Female"
              // : "Unknown"} */}
          </Typography>
        );
      },
    },
    {
      field: "a_as_lvl_activities.activity_id",
      headerName: "Activity",
      width: 130,
      sortable: false,
      renderCell: params => (
        <RenderAlevelCell row={params.row} name="activity_name" />
      ),
    },
    {
      field: "a_as_lvl_activities.role",
      headerName: "Role",
      sortable: false,
      width: 110,
      renderCell: params => <RenderAlevelCell row={params.row} name="role" />,
    },
    {
      field: "a_as_lvl_activities.first_event_id",
      headerName: "First event/ skill/ position",
      width: 130,
      sortable: false,
      renderCell: params => (
        <RenderAlevelCell row={params.row} name="first_event_name" />
      ),
    },
    {
      field: "a_as_lvl_activities.first_event_mark",
      headerName: "Mark",
      width: 85,
      sortable: false,
      renderCell: params => (
        <RenderAlevelCell row={params.row} name="first_event_mark" />
      ),
    },
    {
      field: "a_as_lvl_activities.second_event_id",
      headerName: "Second event/ skill/ position",
      width: 130,
      sortable: false,
      renderCell: params => (
        <RenderAlevelCell row={params.row} name="second_event_name" />
      ),
    },
    {
      field: "a_as_lvl_activities.second_event_mark",
      headerName: "Mark",
      width: 85,
      sortable: false,
      renderCell: params => (
        <RenderAlevelCell row={params.row} name="second_event_mark" />
      ),
    },
    {
      field: "a_as_lvl_activities.third_event_id",
      headerName: "Third event/ skill/ position",
      width: 130,
      sortable: false,
      renderCell: params => (
        <RenderAlevelCell row={params.row} name="third_event_name" />
      ),
    },
    {
      field: "a_as_lvl_activities.third_event_mark",
      headerName: "Mark",
      width: 85,
      sortable: false,
      renderCell: params => (
        <RenderAlevelCell row={params.row} name="third_event_mark" />
      ),
    },
    {
      field: "overall_mark",
      headerName: "Overall activity mark",
      width: 85,
      sortable: false,
      renderCell: params => {
        return params.row.a_as_lvl_activities.candidate_attendance_status ===
          "A" ? (
          <Typography>Not available</Typography>
        ) : params.row.a_as_lvl_activities.candidate_attendance_status ===
          "W" ? (
          <Typography>Withdrawn</Typography>
        ) : (
          <Typography sx={{ fontWeight: "bold" }}>
            {params.value ? `${params.value}/30` : `0/30`}
          </Typography>
        );
      },
    },
    {
      field: "a_as_lvl_activities.filmed_evidence_to_be_provided",
      headerName: "Filmed evidence must be provided",
      width: 110,
      sortable: false,
      renderCell: params => {
        return (
          <Typography>
            {params.row.a_as_lvl_activities.filmed_evidence_to_be_provided ===
            "Y"
              ? "Yes"
              : "No"}
          </Typography>
        );
      },
    },
    {
      field: "a_as_lvl_activities.onsite_filmed_evidence_available_value",
      headerName: "Filmed evidence available",
      width: 70,
      sortable: false,
      renderCell: params => {
        return (
          <Typography>
            {params.row.a_as_lvl_activities
              .onsite_filmed_evidence_available_value === "Y"
              ? "Yes"
              : "No"}
          </Typography>
        );
      },
    },
  ];
  let rowData = [...candidates];
  let rows = [];
  if (
    location.state?.name === QualMapping.ALEVEL ||
    location.state?.name === QualMapping.ASLEVEL
  ) {
    rows = rowData.map((r, i) => {
      return { ...r, id: i };
    });
  } else if (location.state?.name === QualMapping.GCSE) {
    rows = rowData.map((row, i) => {
      const baseSubRow = [
        {
          activityType: "gcse_team_lvl_activities",
          ...row.gcse_team_lvl_activities,
          sex: row.sex,
        },
        {
          activityType: "gcse_indiv_lvl_activities",
          ...row.gcse_indiv_lvl_activities,
          sex: row.sex,
        },
        {
          activityType: "gcse_free_choice_activities",
          ...row.gcse_free_choice_activities,
          sex: row.sex,
        },
      ];

      return {
        ...row,
        id: i,
        activityRows: [...baseSubRow],
        firstEventRows: [...baseSubRow],
        firstEventMarkRows: [...baseSubRow],
        secondEventRows: [...baseSubRow],
        secondEventMarkRows: [...baseSubRow],
        thirdEventRows: [...baseSubRow],
        thirdEventMarkRows: [...baseSubRow],
        totalMarkRows: [...baseSubRow],
        filmedEvidenceRows: [...baseSubRow],
      };
    });
    const filmedEvidence = {
      field: "filmedEvidenceRows",
      headerName: "Filmed evidence must be provided",
      width: 110,
      sortable: false,
      renderCell: ({ value }) => (
        <RenderMultipleRows
          value={value}
          name="filmed_evidence_to_be_provided"
        />
      ),
    };
    columns.push(filmedEvidence);
    height = 120;
  } else if (location.state?.name === QualMapping.ENTRY) {
    rows = rowData.map((row, i) => {
      const baseSubRow = [
        {
          activityType: "entry_lvl_team_activities",
          ...row.entry_lvl_team_activities,
          sex: row.sex,
        },
        {
          activityType: "entry_lvl_indiv_activities",
          ...row.entry_lvl_indiv_activities,
          sex: row.sex,
        },
        {
          activityType: "entry_lvl_free_choice_one_activities",
          ...row.entry_lvl_free_choice_one_activities,
          sex: row.sex,
        },
        {
          activityType: "entry_lvl_free_choice_two_activities",
          ...row.entry_lvl_free_choice_two_activities,
          sex: row.sex,
        },
        {
          activityType: "entry_lvl_perf_analysis",
          ...row.entry_lvl_perf_analysis,
          sex: row.sex,
        },
      ];

      return {
        ...row,
        id: i,
        activity: [...baseSubRow],
        activityRows: [...baseSubRow],
        roleRows: [...baseSubRow],
        firstEventRows: [...baseSubRow],
        firstEventMarkRows: [...baseSubRow],
        secondEventRows: [...baseSubRow],
        secondEventMarkRows: [...baseSubRow],
        thirdEventRows: [...baseSubRow],
        thirdEventMarkRows: [...baseSubRow],
        totalMarkRows: [...baseSubRow],
        filmedEvidenceRows: [...baseSubRow],
      };
    });

    const roles = {
      field: "roleRows",
      headerName: "Role",
      width: 120,
      sortable: false,
      renderCell: ({ value }) => (
        <RenderMultipleRows value={value} name="role" />
      ),
    };
    const filmedEvidence = {
      field: "filmedEvidenceRows",
      headerName: "Filmed evidence available",
      width: 80,
      sortable: false,
      renderCell: ({ value }) => (
        <RenderMultipleRows
          value={value}
          name="onsite_filmed_evidence_available_value"
        />
      ),
    };
    columns.splice(5, 0, roles);
    columns.push(filmedEvidence);
    height = 200;
  }
  const getRowHeight = params => height;
  return (
    <>
      <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
        <CommonHeaderDetails view={true} />
        <DataGrid
          sx={{
            mb: 2,
            mt: 2,
            "& .MuiDataGrid-cellContent": {
              textWrap: "wrap",
            },
            "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
              borderRight: "1px solid #f0f0f0",
            },
            "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
              borderBottom: "1px solid #f0f0f0",
            },
            // "& .MuiDataGrid-columnHeaderTitle":{
            //   fontWeight: "bold"
            // }
            "& .MuiDataGrid-columnHeader": {
              background: "#435a60",
              color: "white",
            },
          }}
          rows={rows}
          columns={
            location.state?.name === QualMapping.ALEVEL ||
            location.state?.name === QualMapping.ASLEVEL
              ? columnsAlevel
              : columns
          }
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          autoHeight
          checkboxSelection={false}
          onSelectionModelChange={() => {}}
          disableColumnMenu
          getRowHeight={getRowHeight}
          showCellRightBorder={true}
        />
        <Button
          sx={{ mb: 2, mt: 2 }}
          variant="contained"
          color="primary"
          onClick={() => navigate("/home/markSubmission")}
        >
          Back
        </Button>
      </Container>
    </>
  );
};
