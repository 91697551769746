import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "react-oidc-context";
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById("root"));
const onSigninCallbackFunc = () => {
  window.location.href = "/";
};
const oidcConfig = {
  authority: process.env.REACT_APP_IDENTITY_AUTHORITY_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_IDENTITY_REDIRECT_URL,
  scope: "openid profile email photoURL roles orgs businessStream uid lastSelectedOrg lastSelectedOrgNCN",
  response_type: "code",
  automaticSilentRenew: true,
  onSigninCallback: onSigninCallbackFunc,
};
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
