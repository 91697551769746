import React, { useEffect } from "react";
import { Divider, Typography, Grid, TextField, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { QualMapping } from "../../utils/constants";
import { useDispatch } from "react-redux";
import {
  fetchModeratorDetails,
  selectPemifData,
} from "../../store/reducers/pemifReducer";
import { useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import { selectCandidates } from "../../store/reducers/candidateReducer";
import { formatDate } from "../../utils/commonFunctions";

export const CommonHeaderDetails = ({ view }) => {
  const location = useLocation();
  const {
    centre_number: cname,
    session_id,
    moderator_name,
    session_name,
    session_year,
    variant,
    margin,
    centre_number,
    centre_name,
    status,
  } = useSelector(selectPemifData);
  const { candidate_details } = useSelector(selectCandidates);
  const dispatch = useDispatch();
  const auth = useAuth();

  useEffect(() => {
    if (status === "idle" && cname && session_id)
      dispatch(
        fetchModeratorDetails({
          centre: cname,
          qual: location.state?.name,
          session: session_id,
          token: auth.user?.access_token,
        })
      );
  }, [status, dispatch, cname, session_id, location.state?.name]);

  return (
    <>
      <Box sx={{ mt: 5, mb: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          PE Marks submission
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          {location.state?.name === QualMapping.ENTRY
            ? `Entry Level Physical Education`
            : location.state?.name === QualMapping.GCSE
            ? `GCSE Physical Education`
            : location.state?.name === QualMapping.ALEVEL
            ? `A Level Physical Education`
            : `AS Level Physical Education`}
        </Typography>
      </Box>
      <Divider variant="fullWidth" />
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={2}>
          <Typography variant="body2">Centre number</Typography>
          <TextField
            variant={variant}
            margin={margin}
            name="centre_number"
            placeholder="Center number"
            value={centre_number}
            sx={{ width: "90%", mt: "3px" }}
            disabled
          />
        </Grid>
        <Grid item xs={view?3:4}>
          <Typography variant="body2">Centre name</Typography>
          <TextField
            variant={variant}
            margin={margin}
            name="centre_name"
            placeholder="Center name"
            value={centre_name}
            sx={{ width: "90%", mt: "3px" }}
            disabled
          />
        </Grid>
        <Grid item xs={view?2:3}>
          <Typography variant="body2">PE moderation contact</Typography>
          <TextField
            variant={variant}
            margin={margin}
            name="moderation_contact_name"
            placeholder="PE moderation contact"
            value={moderator_name}
            sx={{ width: "80%", mt: "3px" }}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">Examination series</Typography>
          <TextField
            variant={variant}
            margin={margin}
            name="centre_name"
            placeholder="Examination series"
            value={`${session_name} ${session_year}`}
            sx={{ width: "80%", mt: "3px" }}
            disabled
          />
        </Grid>
        {view && (
          <>
            <Grid item xs={2}>
              <Typography variant="body2">Submitted by</Typography>
              <TextField
                variant={variant}
                margin={margin}
                name="pe_centre_contact_name"
                placeholder="Submitted by"
                value={candidate_details?.pe_centre_contact_name}
                sx={{ width: "80%", mt: "3px" }}
                disabled
              />
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body2">Submitted date</Typography>
              <TextField
                variant={variant}
                margin={margin}
                name="submitted_datetime"
                placeholder="Submitted Date"
                value={formatDate(
                  new Date(candidate_details?.submitted_datetime)
                )}
                sx={{ width: "100%", mt: "3px" }}
                disabled
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
