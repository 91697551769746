import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const fetchFirstEvent = createAsyncThunk(
  "first_events/fetchData",
  async ({ qual, activity, sex, role, id, type = null, token }) => {
    let getFirstEventUrl;
    if (role) {
      getFirstEventUrl =
        `${process.env.REACT_APP_MARK_BASEURL}` +
        `markssubmissions/getactivityfirstevents?qualification_id=${qual}&activity_id=${activity}&sex=${sex}&role_id=${role}`;
    } else {
      getFirstEventUrl =
        `${process.env.REACT_APP_MARK_BASEURL}` +
        `markssubmissions/getactivityfirstevents?qualification_id=${qual}&activity_id=${activity}&sex=${sex}`;
    }

    const response = await axios.get(getFirstEventUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: response.data, id: id, type };
  }
);
export const fetchSecondEvent = createAsyncThunk(
  "second_events/fetchData",
  async ({ event, sex, id, type = null, token }) => {

    const response = await axios.get(
      `${process.env.REACT_APP_MARK_BASEURL}` +
        `markssubmissions/getactivitynextevents?current_event_id=${event}&sex=${sex}&next_event_sequence=2`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return { data: response.data, id: id, type };
  }
);
export const fetchThirdEvent = createAsyncThunk(
  "third_events/fetchData",
  async ({ event, sex, id, type = null, token }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_MARK_BASEURL}` +
        `markssubmissions/getactivitynextevents?current_event_id=${event}&sex=${sex}&next_event_sequence=3`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return { data: response.data, id: id, type };
  }
);
const initialState= {
  filmed_evidence_to_be_provided: "",
  onsite_filmed_evidence_available: "",
  first_events: null,
  first_event_row_id: null,
  first_event_row_type: null,
  second_event_row_id: null,
  second_event_row_type: null,
  third_event_row_id: null,
  third_event_row_type: null,
  status: "idle",
  error: null,
  second_events: null,
  third_events: null,
}
const firstEventSlice = createSlice({
  name: "eventData",
  initialState: initialState,
  reducers: {
    resetEventData: state => {
      state= initialState
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFirstEvent.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchFirstEvent.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.first_events = action.payload.data.first_events;
        state.first_event_row_id = action.payload.id;
        state.filmed_evidence_to_be_provided =
          action.payload?.filmed_evidence_to_be_provided;
        state.onsite_filmed_evidence_available =
          action.payload?.onsite_filmed_evidence_available;
        state.first_event_row_type = action.payload?.type;
        [
          "second_event_row_id",
          "second_event_row_type",
          "second_events",
          "third_events",
          "third_event_row_id",
          "third_event_row_type",
        ].map(data => (state[data] = null));
      })
      .addCase(fetchFirstEvent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSecondEvent.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.second_events = action.payload.data.next_events;
        state.second_event_row_id = action.payload.id;
        state.second_event_row_type = action.payload?.type;
        ["third_events", "third_event_row_id", "third_event_row_type"].map(
          data => (state[data] = null)
        );
      })
      .addCase(fetchThirdEvent.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.third_events = action.payload.data.next_events;
        state.third_event_row_id = action.payload.id;
        state.third_event_row_type = action.payload?.type;
      });
  },
});
export const { resetEventData } = firstEventSlice.actions;
export const selectFirstEvent = state => state.eventData;
export default firstEventSlice;
