import React, { createContext, useCallback, useMemo, useReducer } from "react";
import { initialValues } from "./initialValue";
import reducer from "./reducer";
import {
  isText,
  isEmail,
  isPhone,
  isZip,
  isNumber,
  variant,
  margin,
  static_fields,
} from "../utils/constants";
import axios from "axios";
import { getPostData, getSubmitData } from "../utils/getData";
import { useAuth } from "react-oidc-context";
export const MainContext = createContext({
  activeStep: 0,
  allData: {},
  formValues: initialValues,
  handleChange() {},
  handleNext() {},
  handleBack() {},
  handleSave() {},
  handleSubmit() {},
  handleAddDate() {},
  handleRemoveDate() {},
  handleData() {},
  handleRefresh() {},
  handleCenter() {},
  handleFieldChange() {},
  handleCenterDetails() {},
  variant,
  margin,
});

export function MainContextProvider({ children }) {
  const auth = useAuth();
  const accessToken = auth.user?.access_token;
  const [{ activeStep, formValues, allData }, dispatch] = useReducer(reducer, {
    activeStep: 0,
    formValues: initialValues,
    allData: {},
  });

  // Proceed to next step
  const handleNext = useCallback(moderation => {
    return dispatch({ type: "increase", moderation });
  }, []);
  // Go back to prev step
  const handleBack = useCallback(
    moderation => dispatch({ type: "decrease", moderation }),
    []
  );

  const handleSave = async values => {
    const postData = getPostData(values);

    try {

      const response = await axios.post(
        process.env.REACT_APP_API_BASEURL + `save_submit`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response && true;
    } catch (error) {
      console.error("Error making POST request:", error.message);
      return false;
    }
  };

  const handleSubmit = useCallback((values, step) => {
    const submitData = getSubmitData(values);

    axios
      .post(process.env.REACT_APP_API_BASEURL + `save_submit`, submitData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response => {
        dispatch({ type: "submit", step });
      })
      .catch(error => {
        console.error("Error making POST request:", error.message);
      });
  }, []);

  const handleAddDate = useCallback(() => dispatch({ type: "addDate" }), []);
  const handleRefresh = useCallback(
    () => dispatch({ type: "refresh", data: initialValues }),
    []
  );
  const handleRemoveDate =useCallback((i)=>dispatch({type:"removeDate",index:i}),[])
  const handleData = useCallback(data => {
    dispatch({ type: "set-alldata", name: "allData", data: { ...data } });
    Object.keys(data).map(d => {
      if (static_fields.includes(d) && data[d] !== null) {
        dispatch({
          type: "form-static-value",
          name: d,
          fieldValue: data[d],
          initial: true,
        });
      } else if (d === "unavailable_dates") {
        if (data["visit_arrangement_id"] > 0 && data[d] && data[d].length)
          dispatch({
            type: "unavailable_dates",
            name: d,
            fieldValue: data[d],
            visitId: true,
          });
        else
          dispatch({
            type: "unavailable_dates",
            name: d,
            fieldValue: initialValues[d],
            visitId: false,
          });
      } else if (d === "centre_venue") {
        data[d] &&
          Object.keys(data[d]).map(detail => {
            dispatch({
              type: "form-centre-venue",
              name: detail,
              fieldValue: data[d][detail],
            });
          });
      } else {
        if (d === "moderation_type")
          dispatch({
            type: "form-value",
            name: d,
            fieldValue: data[d] ? data[d] : "Visiting",
          });
        else if (d !== "centre_name" && d !== "centre_number")
          dispatch({ type: "form-value", name: d, fieldValue: data[d] });
      }
    });
    if (data["AS_level_indicator"] || data["A_level_indicator"]) {
      let l = "AS Level Only";
      l =
        data["AS_level_indicator"] === "Y" && data["A_level_indicator"] === "Y"
          ? "AS and A Level"
          : data["AS_level_indicator"] === "Y" &&
            data["A_level_indicator"] === "N"
          ? "AS Level Only"
          : "A Level Only";
      dispatch({ type: "form-value", name: "level", fieldValue: l });
    }
  }, []);
  const handleCenter = useCallback(
    data =>
      Object.keys(data).map(d => {
        dispatch({ type: "form-centre-venue", name: d, fieldValue: data[d] });
      }),
    []
  );
  const handleCenterDetails = useCallback((centre_name, centre_number) => {
    dispatch({ type: "center", centre_name, centre_number });
  }, []);
  // Handle form change
  const handleChange = useCallback((event, checked) => {
    let { type, name, value, dataset } = event.target;
    let fieldValue = type === "checkbox" ? checked : value;

    if (name === "unavailable_dates") {
      dispatch({
        type: "save-date",
        name,
        fieldValue,
        flag: dataset.flag,
        id: dataset.id,
      });
      let err = "";
      if (dataset.flag === "start_date") {
        if (
          value &&
          dataset.check &&
          new Date(value) > new Date(dataset.check)
        ) {
          err = "Invalid date ";
        } else {
          dispatch({
            type: "form-error",
            name: "unavailable_dates",
            index: dataset.id,
            flag: "end_date",
            error: err,
          });
        }
      } else if (dataset.flag === "end_date"){
        if (
          value &&
          dataset.check &&
          new Date(value).setHours(0, 0, 0, 0) < new Date(dataset.check).setHours(0, 0, 0, 0)
        ) {
          err = "Invalid date ";
        } else {
          dispatch({
            type: "form-error",
            name: "unavailable_dates",
            index: dataset.id,
            flag: "start_date",
            error: err,
          });
        }}

      dispatch({
        type: "form-error",
        name: "unavailable_dates",
        index: dataset.id,
        flag: dataset.flag,
        error: err,
      });
    } else if (static_fields.includes(name)) {
      dispatch({ type: "form-static-value", name, fieldValue });
    } else if (
      dataset.flag === "centre_venue" ||
      name === "loc_desc" ||
      name === "facilities_desc"
    ) {
      dispatch({ type: "form-centre-venue", name, fieldValue });
    } else {
      if (name === "level") {
        dispatch({ type: "form-value", name: "reset_values" });
        // fieldValue === "A Level Only" &&
        //   dispatch({ type: "form-value", name: "as_level_activities" });
        // fieldValue === "AS Level Only" &&
        //   dispatch({ type: "form-value", name: "a_level_activities" });
      }
      dispatch({ type: "form-value", name, fieldValue });
    }

    const fieldName =
      dataset.flag === "centre_venue"
        ? initialValues["centre_venue"][name]
        : initialValues[name];
    if (!fieldName) return;

    const { required, validate, minLength, maxLength, helperText } = fieldName;

    let error = "";

    if (required && !fieldValue) error = "Please enter the details";
    if (minLength && value && value.length < minLength)
      error = `Minimum ${minLength} characters is required.`;
    if (maxLength && value && value.length > maxLength)
      error = "Maximum length exceeded!";
    if (validate) {
      switch (validate) {
        case "text":
          if (value && !isText.test(value))
            error = helperText || "This field accepts text only.";
          break;
        case "number":
          if (value && !isNumber.test(value))
            error = helperText || "This field accepts numbers only.";
          break;
        case "moderation_contact_email":
          if (value && !isEmail.test(value))
            error =
              helperText ||
              "Please enter a valid moderation contact email address.";
          break;
        case "moderation_contact_telephone":
          if (value && !isNumber.test(value))
            error =
              helperText ||
              "Please enter a valid moderation contact telephone number. i.e: xxx-xxx-xxxx";
          break;
        default:
          break;
      }
    }

    name !== "unavailable_dates" &&
      !static_fields.includes(name) &&
      dispatch({ type: "form-error", name, error, flage: dataset.flag });
  }, []);
  const handleFieldChange = useCallback((e, i, activities) => {
    dispatch({
      type: "set-table",
      name: e.target.name,
      value: e.target.value,
      index: i,
      activities,
    });
  }, []);
  const contextValue = useMemo(
    () => ({
      activeStep,
      formValues,
      handleChange,
      handleNext,
      handleBack,
      handleSave,
      handleSubmit,
      handleAddDate,
      handleRemoveDate,
      handleCenter,
      handleCenterDetails,
      handleData,
      handleRefresh,
      handleFieldChange,
      allData,
      variant,
      margin,
    }),
    [
      activeStep,
      formValues,
      allData,
      handleChange,
      handleNext,
      handleBack,
      handleSave,
      handleCenterDetails,
      handleSubmit,
      handleAddDate,
      handleRemoveDate,
      handleData,
      handleRefresh,
      handleCenter,
      handleFieldChange,
    ]
  );

  return (
    <MainContext.Provider value={contextValue}>
      <>{children}</>
    </MainContext.Provider>
  );
}
