import React, { useCallback, useContext } from "react";
import StepActions from "../common/StepActions";
import {
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import { MainContext } from "../../context/Context";

export default function SixthStep() {
  const { formValues, handleChange, handleBack, handleNext, handleSave } =
    useContext(MainContext);
  const { moderation_type } = formValues;
  const onNext = () => {
    handleNext(moderation_type.value);
  };

  const isError = useCallback(
    () =>
      Object.keys({
        moderation_type,
      }).some(
        name =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, moderation_type]
  );

  return (
    <>
      <Box sx={{ mt: 5, mb: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Type of moderation
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Select the moderation method you will use:
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={moderation_type.value}
            onChange={handleChange}
            name="moderation_type"
          >
            <FormControlLabel
              value="Postal"
              control={<Radio />}
              label="Postal moderation"
            />
            <FormControlLabel
              value="Upload"
              control={<Radio />}
              label="Upload moderation"
            />
            <FormControlLabel
              value="Visiting"
              control={<Radio />}
              label="Visiting moderation"
            />
          </RadioGroup>
        </Grid>
      </Grid>
      <StepActions
        isError={isError}
        handleBack={handleBack}
        handleNext={onNext}
        handleSave={handleSave}
      />
    </>
  );
}
