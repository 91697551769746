import { data, static_fields } from "./constants";

const getDataMapping = values => {
  let postData = { ...data };
  static_fields.map(d => {
    postData[d] = values[d];
  });
  Object.keys(data).map(d => {
    if (data[d] === null) {
      if (d === "centre_venue") {
        if (values["centre_host_venue_indicator"] === "Y") {
          let centreTemp = {
            address_line_1: "",
            address_line_2: "",
            city: "",
            county: "",
            postcode: "",
            country_code: "",
            loc_desc: "",
            facilities_desc: "",
          };
          Object.keys(centreTemp).map(c => {
            centreTemp[c] = values["centre_venue"][c].value;
          });
          postData[d] = centreTemp;
        } else postData[d] = null;
      } else if (d === "unavailable_dates") {
        let temp = [];
        let hasDate = false;
        values[d].map(v => {
          if (v["start_date"].value !== "" || v["end_date"].value !== "") {
            hasDate = true;
          }
        });
        if (hasDate) {
          values[d].map(v => {
            let obj = {
              start_date: "",
              end_date: "",
              easter_indicator: "",
            };
            obj.start_date = v.start_date.value;
            obj.end_date = v.end_date.value;
            obj.easter_indicator = v.easter_indicator;
            temp.push(obj);
          });
          postData[d] = temp;
        } else postData[d] = null;
      } else {
        postData[d] = values[d].value === "" ? null : values[d].value;
      }
    } else postData[d] = values[d];
  });
  if (values.level.value === "AS and A Level") {
    postData.AS_level_indicator = "Y";
    postData.A_level_indicator = "Y";
  } else if (values.level.value === "AS Level Only") {
    postData.AS_level_indicator = "Y";
    postData.A_level_indicator = "N";
  } else if (values.level.value === "A Level Only") {
    postData.AS_level_indicator = "N";
    postData.A_level_indicator = "Y";
  }
  if (
    ["1787", "159", "440"].includes(values.qualifications[0].qualification_id)
  ) {
    postData.centre_venue =
      values.centre_host_venue_indicator === "N" ? {} : postData.centre_venue;
  } else {
    postData.unavailable_dates =
      values.moderation_type.value !== "Visiting"
        ? null
        : postData.unavailable_dates;
    postData.centre_venue =
      values.moderation_type.value !== "Visiting" ||
      values.centre_host_venue_indicator === "N"
        ? {}
        : postData.centre_venue;

    if (values.moderation_type.value === "Visiting") {
      let centreTemp = {
        address_line_1: "",
        address_line_2: "",
        city: "",
        county: "",
        postcode: "",
        country_code: "",
        loc_desc: "",
        facilities_desc: "",
      };
      Object.keys(centreTemp).map(c => {
        centreTemp[c] = values["centre_venue"][c].value;
      });
      postData.centre_venue = { ...centreTemp };
      postData.centre_host_venue_indicator = "Y";
    } else if (
      values.moderation_type.value !== "" &&
      values.moderation_type.value !== "Visiting"
    ) {
      postData.centre_host_venue_indicator = "N";
    }
  }
  return postData;
};
export const getPostData = values => {
  let saveData = getDataMapping(values);
  saveData.submit = "N";
  return saveData;
};
export const getSubmitData = values => {
  let submitData = getDataMapping(values);
  submitData.declaration_date = new Date().toISOString().split("T")[0];
  submitData.submit = "Y";
  return submitData;
};
