import React, { useEffect } from "react";
import { uniqBy } from "lodash";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
  Select,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchModeratorDetails,
  selectPemifData,
} from "../../../store/reducers/pemifReducer";
import { useLocation } from "react-router-dom";
import { selectActivity } from "../../../store/reducers/activityReducer";
import {
  handleSelectedActivities,
  selectCandidates,
} from "../../../store/reducers/candidateReducer";
import { QualMapping } from "../../../utils/constants";

const StaticData = ({ qual }) => {
  const location = useLocation();
  const { session_name, session_year } = useSelector(selectPemifData);
  const dispatch = useDispatch();
  const { activities } = useSelector(selectActivity);
  const { candidates = [], selectedActivities = [] } =
    useSelector(selectCandidates);
  useEffect(() => {
    let savedActivitiesList = uniqBy(
      candidates.map((val) => val.a_as_lvl_activities) || [],
      "activity_id"
    ).filter((act) => act?.activity_id);
    dispatch(handleSelectedActivities(savedActivitiesList));
  }, []);

  const options = uniqBy(
    [
      ...(activities?.male_Activities || []),
      ...(activities?.female_Activities || []),
    ],
    "activity_id"
  );

  const handleChange = (event) => {
    let selectedOptions = options.filter((val) =>
      event.target.value.includes(val.activity_name)
    );
    dispatch(handleSelectedActivities(selectedOptions));
  };
  return (
    <>
      {(qual === QualMapping.ALEVEL || qual === QualMapping.ASLEVEL) && (
        <>
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mb: 1,
                fontSize: {
                  xs: "15px !important",
                  sm: "20px !important",
                  md: "20px !important",
                },
              }}
            >
              {location.state?.name === QualMapping.ENTRY
                ? `Entry Level Physical Education`
                : location.state?.name === QualMapping.GCSE
                ? `GCSE Physical Education`
                : location.state?.name === QualMapping.ALEVEL
                ? `A Level Physical Education`
                : `AS Level Physical Education`}{" "}
              activity marks submission {session_name} {session_year}
            </Typography>
            <Typography variant="body2" >
              For each candidate:
            </Typography>
            <List
              sx={{
                lineHeight: 0,
                listStyleType: "disc",
                marginLeft: "0.75em",
                "& .MuiListItem-root": {
                  display: "list-item",
                  px: 0.5,
                  py: 0.5,
                },
              }}
            >
              <ListItem>
                <ListItemText>
                  <Typography variant="body2" >
                    Select the activity and role from the dropdown list. You can
                    use the activity filter to select the activities your cohort
                    have completed.
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography variant="body2" >
                    Enter each event, skill or position and the marks for these.
                    The overall activity mark will be calculated automatically.
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography variant="body2" >
                    If they were part of your on-site sample. select 'Yes' in
                    the final column
                  </Typography>
                </ListItemText>
              </ListItem>
              <Typography variant="body2" sx={{ lineHeight: "1.8" ,mt:1}}>
                Click 'Submit' to submit your marks, To save the data without
                submitting, click 'Save and close' and your details will be
                saved for later.
              </Typography>
            </List>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1,ml:2 }}>
              <Typography variant="body2" sx={{ lineHeight: "1.8" }}>
                {" "}
                Activities{" "}
              </Typography>
              <FormControl>
                <InputLabel sx={{ textAlign: "center" }}>
                  Select Options
                </InputLabel>
                <Select
                  label="Select Options"
                  multiple
                  value={
                    selectedActivities.map((value) => value.activity_name) || []
                  }
                  onChange={handleChange}
                  renderValue={(selected) =>
                    selected.length > 0 ? selected[selected.length - 1] : ""
                  }
                  sx={{
                    width: 200,
                    height: 36,
                    fontSize: 14,
                    padding: "4px 8px",
                  }}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option.activity_id}
                      value={option.activity_name}
                    >
                      <Checkbox
                        checked={
                          (
                            selectedActivities.map(
                              (value) => value.activity_name
                            ) || []
                          ).indexOf(option.activity_name) > -1
                        }
                      />
                      <ListItemText primary={option.activity_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </>
      )}

      {qual === QualMapping.GCSE && (
        <List
          sx={{
            mb: 2,
            lineHeight: 0,
            listStyleType: "disc",
            marginLeft: "0.75em",
            "& .MuiListItem-root": {
              display: "list-item",
              px: 0.5,
              py: 0.5,
            },
          }}
        >
          <ListItem>
            <ListItemText>
              <Typography variant="h5">
                For each candidate, make sure the sex listed is the sex assigned
                at birth.
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText>
              <Typography variant="h5">
                For each candidate, select the activity and role from the
                dropdown lists and enter the details and marks as shown. The
                marks will total automatically and must match the component
                marks you submit via Interchange or EDI.
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      )}

      {qual === QualMapping.ENTRY && (
        <>
          <List
            sx={{
              lineHeight: 0,
              listStyleType: "disc",
              marginLeft: "0.75em",
              "& .MuiListItem-root": {
                display: "list-item",
                px: 0.5,
                py: 0.5,
              },
            }}
          >
            <ListItem>
              <ListItemText>
                <Typography variant="h5">
                  For each candidate, make sure the sex listed is the sex
                  assigned at birth.
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Typography variant="h5">
                  For each candidate, select the activity and role from the
                  dropdown lists and enter the details and marks as shown. The
                  marks will total automatically and must match the component
                  marks you submit via Interchange or EDI.
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Typography variant="h5">
                  For each candidate, select ‘Yes’ in the ‘Filmed evidence
                  available’ column to show if activities have been filmed.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          <Typography variant="body1" sx={{ mb: 1 }}>
            You will not be able to submit the form until all the information
            has been provided for all candidates.
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            You can save, close and return to the form before you submit it.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Once you have submitted the form you can no longer make any changes.
            If you need to make changes after you have submitted the form,
            please contact us at{" "}
            {/* <Link
              href="https://support.ocr.org.uk/"
              color="#0000e8"
              underline="always"
            >
              {"support@ocr.org.uk"}{" "}
            </Link> */}
            <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
          </Typography>
        </>
      )}
    </>
  );
};
export default StaticData;
