export const formatDate = date => {
  if (date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  } else return "";
};

export const containsEvent = (allEvents, activityID) => {
  if (!allEvents)
    return false;

  const data = allEvents.filter(event => event.activity_id === activityID);
  return data.length ? true : false;
};

export const isDisabledSex = (params) => {
  return params.row.original.candidate_attendance_status === "A" ||
    params.row.original.candidate_attendance_status === "W"
}

export const isFirstEventDisabled = (params, activities) => {
  return !params.row.getValue("sex") ||
    !params.row.getValue("activity_id") || params.row.getValue("role") === "Coach" ||
    !params.row.getValue("role") ||
    (params.row.getValue("sex") === "F" &&
      activities?.female_Activities &&
      !activities?.female_Activities.filter(
        (x) => x.activity_id == params.row.getValue("activity_id")
      )[0]?.first_events) ||
    (params.row.getValue("sex") === "M" &&
      activities?.male_Activities &&
      !activities?.male_Activities.filter(
        (x) => x.activity_id == params.row.getValue("activity_id")
      )[0]?.first_events) ||
    (params.row.getValue("sex") === "F" &&
      activities?.female_Activities &&
      !activities?.female_Activities
        .filter(
          (x) => x.activity_id == params.row.getValue("activity_id")
        )[0]
        .first_events.filter(
          (x) =>
            x.event_role_name === params.row.getValue("role") &&
            (x.event_sex_restriction == "None" ||
              x.event_sex_restriction == "Female")
        )) ||
    (params.row.getValue("sex") === "M" &&
      activities?.male_Activities &&
      !activities?.male_Activities
        .filter(
          (x) => x.activity_id == params.row.getValue("activity_id")
        )[0]
        .first_events.filter(
          (x) =>
            x.event_role_name === params.row.getValue("role") &&
            (x.event_sex_restriction == "None" ||
              x.event_sex_restriction == "Male")
        ))
}

export const isFirstEventMarkDisabled = (row) => {
  return !row.getValue("sex") ||
    !row.getValue("activity_id") ||
    !row.getValue("role")
}

export const isSecondEventIdDisabled = (params, activities) => {
  return !params.row.getValue("sex") ||
    !params.row.getValue("activity_id") ||
    !params.row.getValue("role") || params.row.getValue("role") === "Coach" ||
    !params.row.getValue("first_event_id") ||
    (params.row.getValue("sex") === "F" &&
      activities?.female_Activities &&
      !activities?.female_Activities.filter(
        (x) => x.activity_id == params.row.getValue("activity_id")
      )[0]?.first_events) ||
    (params.row.getValue("sex") === "M" &&
      activities?.male_Activities &&
      !activities?.male_Activities.filter(
        (x) => x.activity_id == params.row.getValue("activity_id")
      )[0]?.first_events) ||
    (params.row.getValue("sex") === "M" &&
      activities?.male_Activities &&
      (!activities?.male_Activities.filter(
        (x) => x.activity_id == params.row.getValue("activity_id")
      )[0]?.first_events ||
        !activities?.male_Activities
          .filter(
            (x) => x.activity_id == params.row.getValue("activity_id")
          )[0]
          ?.first_events?.filter(
            (x) => x.event_id == params.row.getValue("first_event_id")
          )[0]?.next_level_events ||
        !activities?.male_Activities
          .filter(
            (x) => x.activity_id == params.row.getValue("activity_id")
          )[0]
          ?.first_events?.filter(
            (x) => x.event_id == params.row.getValue("first_event_id")
          )[0]
          ?.next_level_events.filter(
            (x) =>
              x.event_sex_restriction === "None" ||
              x.event_sex_restriction == "Male"
          ))) ||
    (params.row.getValue("sex") === "F" &&
      activities?.female_Activities &&
      (!activities?.female_Activities.filter(
        (x) => x.activity_id == params.row.getValue("activity_id")
      )[0]?.first_events ||
        !activities?.female_Activities
          .filter(
            (x) => x.activity_id == params.row.getValue("activity_id")
          )[0]
          ?.first_events?.filter(
            (x) => x.event_id == params.row.getValue("first_event_id")
          )[0]?.next_level_events ||
        !activities?.female_Activities
          .filter(
            (x) => x.activity_id == params.row.getValue("activity_id")
          )[0]
          ?.first_events?.filter(
            (x) => x.event_id == params.row.getValue("first_event_id")
          )[0]
          ?.next_level_events.filter(
            (x) =>
              x.event_sex_restriction === "None" ||
              x.event_sex_restriction == "Female"
          )))
}

export const isSecondEventMarkDisabled = (row, activities) => {
  return !row.getValue("sex") ||
    !row.getValue("activity_id") ||
    !row.getValue("role") ||
    !row.getValue("first_event_id") ||
    !row.getValue("second_event_id") ||
    (row.getValue("sex") === "F" &&
      activities?.female_Activities &&
      !activities?.female_Activities.filter(
        (x) => x.activity_id == row.getValue("activity_id")
      )[0]?.first_events) ||
    (row.getValue("sex") === "M" &&
      activities?.male_Activities &&
      !activities?.male_Activities.filter(
        (x) => x.activity_id == row.getValue("activity_id")
      )[0]?.first_events) ||
    (row.getValue("sex") === "M" &&
      activities?.male_Activities &&
      (!activities?.male_Activities.filter(
        (x) => x.activity_id == row.getValue("activity_id")
      )[0]?.first_events ||
        !activities?.male_Activities
          .filter(
            (x) => x.activity_id == row.getValue("activity_id")
          )[0]
          ?.first_events?.filter(
            (x) => x.event_id == row.getValue("first_event_id")
          )[0]?.next_level_events)) ||
    (row.getValue("sex") === "F" &&
      activities?.female_Activities &&
      (!activities?.female_Activities.filter(
        (x) => x.activity_id == row.getValue("activity_id")
      )[0]?.first_events ||
        !activities?.female_Activities
          .filter(
            (x) => x.activity_id == row.getValue("activity_id")
          )[0]
          ?.first_events?.filter(
            (x) => x.event_id == row.getValue("first_event_id")
          )[0]?.next_level_events))
}

export const isThirdEventIdDisabled = (params, activities) => {
  return !params.row.getValue("sex") ||
    !params.row.getValue("activity_id") ||
    !params.row.getValue("role") ||
    !params.row.getValue("first_event_id") ||
    !params.row.getValue("second_event_id") ||
    (params.row.getValue("sex") === "F" &&
      !activities?.female_Activities) ||
    (params.row.getValue("sex") === "M" &&
      !activities?.male_Activities) ||
    (params.row.getValue("sex") === "F" &&
      !activities?.female_Activities.filter(
        (x) => x.activity_id == params.row.getValue("activity_id")
      )[0]?.first_events) ||
    (params.row.getValue("sex") === "M" &&
      !activities?.male_Activities.filter(
        (x) => x.activity_id == params.row.getValue("activity_id")
      )[0]?.first_events) ||
    (params.row.getValue("sex") === "F" &&
      !activities?.female_Activities
        .filter(
          (x) => x.activity_id == params.row.getValue("activity_id")
        )[0]
        ?.first_events?.filter(
          (x) => x.event_id == params.row.getValue("first_event_id")
        )[0]?.next_level_events) ||
    (params.row.getValue("sex") === "M" &&
      !activities?.male_Activities
        .filter(
          (x) => x.activity_id == params.row.getValue("activity_id")
        )[0]
        ?.first_events?.filter(
          (x) => x.event_id == params.row.getValue("first_event_id")
        )[0]?.next_level_events) ||
    (params.row.getValue("sex") === "F" &&
      !activities?.female_Activities
        .filter(
          (x) => x.activity_id == params.row.getValue("activity_id")
        )[0]
        ?.first_events.filter(
          (x) => x.event_id == params.row.getValue("first_event_id")
        )[0]
        ?.next_level_events.filter(
          (x) => x.event_id == params.row.getValue("second_event_id")
        )[0]?.next_level_events) ||
    (params.row.getValue("sex") === "M" &&
      !activities?.male_Activities
        .filter(
          (x) => x.activity_id == params.row.getValue("activity_id")
        )[0]
        ?.first_events.filter(
          (x) => x.event_id == params.row.getValue("first_event_id")
        )[0]
        ?.next_level_events.filter(
          (x) => x.event_id == params.row.getValue("second_event_id")
        )[0]?.next_level_events)
}

export const isThirdEventMarkDisabled = (row, activities) => {
  return !row.getValue("sex") ||
    !row.getValue("activity_id") ||
    !row.getValue("role") ||
    !row.getValue("first_event_id") ||
    !row.getValue("second_event_id") ||
    (row.getValue("sex") === "F" && !activities?.female_Activities) ||
    (row.getValue("sex") === "M" && !activities?.male_Activities) ||
    (row.getValue("sex") === "F" &&
      !activities?.female_Activities.filter(
        (x) => x.activity_id == row.getValue("activity_id")
      )[0]?.first_events) ||
    (row.getValue("sex") === "M" &&
      !activities?.male_Activities.filter(
        (x) => x.activity_id == row.getValue("activity_id")
      )[0]?.first_events) ||
    (row.getValue("sex") === "F" &&
      !activities?.female_Activities
        .filter(
          (x) => x.activity_id == row.getValue("activity_id")
        )[0]
        ?.first_events?.filter(
          (x) => x.event_id == row.getValue("first_event_id")
        )[0]?.next_level_events) ||
    (row.getValue("sex") === "M" &&
      !activities?.male_Activities
        .filter(
          (x) => x.activity_id == row.getValue("activity_id")
        )[0]
        ?.first_events?.filter(
          (x) => x.event_id == row.getValue("first_event_id")
        )[0]?.next_level_events) ||
    (row.getValue("sex") === "F" &&
      !activities?.female_Activities
        .filter(
          (x) => x.activity_id == row.getValue("activity_id")
        )[0]
        ?.first_events.filter(
          (x) => x.event_id == row.getValue("first_event_id")
        )[0]
        ?.next_level_events.filter(
          (x) => x.event_id == row.getValue("second_event_id")
        )[0]?.next_level_events) ||
    (row.getValue("sex") === "M" &&
      !activities?.male_Activities
        .filter(
          (x) => x.activity_id == row.getValue("activity_id")
        )[0]
        ?.first_events.filter(
          (x) => x.event_id == row.getValue("first_event_id")
        )[0]
        ?.next_level_events.filter(
          (x) => x.event_id == row.getValue("second_event_id")
        )[0]?.next_level_events)
}