import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCandidates,
  handleChange,
  handleEntryChange,
  handleChangeGcse
} from "../../../store/reducers/candidateReducer";
import { selectPemifData } from "../../../store/reducers/pemifReducer";
import { Typography, Button, Box } from "@mui/material";
import { QualMapping } from "../../../utils/constants";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useLocation } from 'react-router-dom';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const CandidateReviewTable = ({ }) => {
  const { reviewPageError } = useSelector(selectPemifData);
  const { candidates } = useSelector(selectCandidates);
  const location = useLocation();
  const dispatch = useDispatch();
  const rowData = [...candidates];
  let rows = rowData.map((candidate) => ({
    candidate_number: candidate.candidate_number,
    candidate_name: candidate.candidate_name,
    sex: candidate.sex,
  }));
  const [data, setData] = useState(rows);

  const columns = useMemo(
    () => [
      {
        accessorKey: "candidate_number",
        header: "Candidate number",
        enableEditing: false,
        size: 230,
        sortingFn: "alphanumeric",
        Cell: ({ row }) => (
          <Typography variant="body1" sx={{ width: "100%" }}>
            {row.original.candidate_number}
          </Typography>
        ),
      },
      {
        accessorKey: "candidate_name",
        header: "Candidate name",
        enableEditing: false,
        size: 250,
        Cell: ({ row }) => (
          <Typography variant="body1" sx={{ width: "100%" }}>
            {row.original.candidate_name}
          </Typography>
        ),
      },
      {
        accessorKey: "sex",
        header: "Sex assigned at birth",
        size: 230,
        editVariant: "select",
        editSelectOptions: [
          { value: "M", label: "Male" },
          { value: "F", label: "Female" },
        ],
        muiEditTextFieldProps: (params) => {
          return {
            select: true,
            label: Boolean(params.cell.getValue().trim()) ? (
              ""
            ) : (
              <span
                style={{
                  fontSize: "18px",
                  display: "block",
                  marginLeft: "2px",
                  marginTop: "13px",
                }}
              >
                Please select
              </span>
            ),
            value: params.cell.getValue()
              ? params.cell.getValue()
              : "Please select",
            onChange: (event) => {
              const selectedValue = event.target.value;
              data[params.row.index].sex = event.target.value;
              {
                location.state?.name === QualMapping.ENTRY
                ? dispatch(
                  handleEntryChange({
                    event: {
                      name: params.column.id,
                      value: event.target.value
                    },
                    id: params.row.id,
                    qual: "entryLevel"
                  })
                )
                : location.state?.name === QualMapping.GCSE
                  ? dispatch(
                    handleChangeGcse({
                      event: {
                        name: params.column.id,
                        value: event.target.value
                      },
                      id: params.row.id,
                      qual: 'gcse'
                    })
                  )
                  :
                  dispatch(
                    handleChange({
                      event: {
                        name: event.target.name,
                        value: event.target.value,
                      },
                      id: params.row.id,
                    })
                  )
              }
            },
            InputLabelProps: {
              sx: {
                color: "black",
              },
            },
          };
        },
        Cell: (params) => {
          return (
            <Typography sx={{ lineHeight: "56px" }}>
              {params.cell.getValue() === "F"
                ? "Female"
                : params.cell.getValue() === "M"
                  ? "Male"
                  : "Please Select"}
            </Typography>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableSorting: true,
    enableColumnPinning: true,
    enableColumnActions: false,
    enableColumnResizing: true,
    enableEditing: true,
    enableKeyboardShortcuts: true,
    editDisplayMode: "table",
    initialState: {
      sorting: [{ id: "candidate_number", desc: false }],
      columnPinning: { left: ["candidate_number", "candidate_name"] },
      pagination: { pageIndex: 0, pageSize: 20 },
    },
    muiTableBodyProps: {
      sx: {
        maxHeight: "400px",
        //  overflowY: "auto",
      },
    },
    muiTableHeadProps: {
      sx: { backgroundColor: "#8884b4" },
    },
    muiTableBodyCellProps: {
      sx: { paddingLeft: "15px", padding: "2px" },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#8884b4 ",
        "&:before": {
          backgroundColor: "#8884b4 !important",
        },
      },
    },
  });

  const handleDownloadExcel = () => {
    const displayedData = table.getRowModel().rows.map((row) => ({
      "Candidate number": row.original.candidate_number,
      "Candidate name": row.original.candidate_name,
      "Sex assigned at birth":
        row.original.sex === "M"
          ? "Male"
          : row.original.sex === "F"
            ? "Female"
            : "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(displayedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Candidates");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "CandidateData.xlsx");
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", paddingBottom: "60px", position: "relative", minHeight: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          flex: 1,
        }}
      >
        <Box sx={{ maxWidth: "750px", mb: 2 }}>
          <MaterialReactTable table={table} />
        </Box>
        {reviewPageError && (
          <Typography
            sx={{
              display: "flex",
              flex: 2,
              color: "red",
              fontSize: {
                xs: "14px !important",
                sx: "14px !important",
                md: "15px !important",
              },
              maxWidth: "450px",
              marginLeft: { md: "40px", xs: "20px", sm: "20px" },
              alignSelf: { md: "center", xs: "start" },
              marginTop: { xs: "16px", sm: "16px", md: 0 },
              textAlign: { xs: "start", md: "start" },
            }}
          >
            Please update the value in the "Sex assigned at birth" field where
            this currently displays 'Please Select'
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          position: "absolute",
          left: "40%",
          transform: "translateX(-50%)", // Center horizontally
          bottom: "-20px",
          display: "flex",
          justifyContent: "center",
          mt: 1
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadExcel}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "31.6px",
          }}
        >
          Download
        </Button>
      </Box>
    </div>
  );
};

export default CandidateReviewTable;
