export const initialValues = {
  session_id: "",
  session_name: "",
  qualifications: [],
  centre_number: "",
  centre_name:"",
  expected_entries_indicator: "",
  submit: "",
  moderation_contact_name: {
    value: "",
    error: "",
    required: true,
    maxLength: 100,
    validate: "text",
    helperText: "Enter Moderation contact name",
  },
  moderation_contact_role: {
    value: "",
    error: "",
    required: true,
    maxLength: 50,
    validate: "text",
    helperText: "Enter Moderation contact role",
  },
  moderation_contact_email: {
    value: "",
    error: "",
    validate: "moderation_contact_email",
    required: true,
    maxLength: 50,
    helperText: "Enter Moderation contact email id",
  },
  moderation_contact_telephone: {
    value: "",
    error: "",
    validate: "moderation_contact_telephone",
    maxLength: 11,
    minLength:10,
    required: true,
    helperText: "Enter Moderation contact telephone",
  },
  moderation_contact_extension: {
    value: "",
    error: "",
    validate: "number",
    maxLength: 10,
    helperText: "Enter Moderation contact extension",
  },
  unavailable_dates: [
    {
      start_date: {
        value: "",
        error: "",
        required: true,
        validate: "firstDate",
      },
      end_date: {
        value: "",
        error: "",
        required: true,
        validate: "lastDate",
      },
      easter_indicator: "Y",
    },
    {
      start_date: {
        value: "",
        error: "",
        validate: "firstDate",
      },
      end_date: {
        value: "",
        error: "",
        validate: "lastDate",
      },
      easter_indicator: "N",
    },
  ],
  A_level_indicator: {value:""},
  AS_level_indicator: {value:""},
  moderation_type: {value:""},
  centre_host_venue_indicator: {value:"N"},
  centre_venue: {
    address_line_1: {
      value: "",
      error: "",
      validate: "text",
      maxLength: 100,
      required: true,
    },
    address_line_2: {
      value: "",
      error: "",
      validate: "text",
      maxLength: 100
    },
    county: {
      value: "",
      error: "",
      validate: "text",
      maxLength: 50
    },
    postcode: {
      value: "",
      error: "",
      validate: "text",
      maxLength: 8,
      required: true,
    },
    city: {
      value: "",
      error: "",
      validate: "text",
      maxLength: 50,
      required: true,
    },
    country_code: {
      value: "",
      error: "",
      validate: "text",
      maxLength: 8,
    },
    loc_desc: {
      value: "",
      error: "",
      validate: "text",
    },
    facilities_desc: {
      value: "",
      error: "",
      validate: "text",
    },
  },
  facilities_addl_info: {
    value: "",
    error: "",
    validate: "text",
  },
  moderator_addl_info: {
    value: "",
    error: "",
    validate: "text",
  },
  submission_person_name: {
    value: "",
    error: "",
    validate: "text",
    maxLength : 50,
    required: true,
  },
  declaration_date: {
    value: "",
    error: "",
    required: true,
  },
  a_level_activities: [],
  as_level_activities: [],
  gcse_activities_individual: [],
  gcse_activities_team: [],
  entrylvl_activities_individual: [],
  entrylvl_activities_team: [],
  level:{value:"AS Level Only"}
};
