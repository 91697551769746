import React from "react";
import { Button, Grid } from "@mui/material";
 
export const LandingPageButtons = () => {
  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.assign(
      `${process.env.REACT_APP_MY_CAMBRIDGE_LOGOUT_LINK}?redirect_uri=${process.env.REACT_APP_IDENTITY_LOGOUT_REDIRECT_URL}&client_id=${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
    );
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{ display: "flex", justifyContent: "space-evenly", my: 4 }}
    >
      <Grid item xs={12} sm={4}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "fit-content",
            mt: 2,
            ml: { xs: 3, sm: 8, md: 9, lg: 11 },
          }}
          onClick={() => {
            window.location.assign(process.env.REACT_APP_MY_CAMBRIDGE_HOMEPAGE);
          }}
        >
          Go Back to My Cambridge
        </Button>
      </Grid>
 
      <Grid item xs={12} sm={4}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "fit-content", mt: 2, ml: { xs: 3,sm:4, md: 5, lg: 6 } }}
          onClick={handleLogout}
        >
          Sign Out
        </Button>
      </Grid>
    </Grid>
  );
};