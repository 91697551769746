import React, { useCallback, useContext } from "react";
import {
  Typography,
  Box,
  TextField,
  Grid,
} from "@mui/material";
import StepActions from "../common/StepActions";
import { useLocation } from "react-router-dom";
import { MainContext } from "../../context/Context";
import { QualMapping } from "../../utils/constants";

export default function Confirm() {
  const location = useLocation();
  const {
    formValues,
    handleChange,
    handleBack,
    handleSave,
    handleSubmit,
    variant,
    margin,
  } = useContext(MainContext);
  const { submission_person_name, expected_entries_indicator } = formValues;

  const isError = useCallback(
    () =>
      Object.keys({ submission_person_name }).some(
        name =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [formValues, submission_person_name]
  );
  const onSubmit = () => {
    // Remove unwanted properties from formValue object
    // let form = {};

    // Object.keys(formValues).map(name => {
    //   form = {
    //     ...form,
    //     [name]: formValues[name].value,
    //   };
    //   return form;
    // });
    // Do whatever with the values
    // console.log(form);
    // Show last component or success message
    handleSubmit(formValues, location.state ?.name);
  };

  return (
    <>
    <Box sx={{ mt: 5, mb: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Declaration
        </Typography>
      {expected_entries_indicator === "N" ? (
          <>
        <Typography variant="body2" sx={{ mt: 4 }}>
          {`Thank you for confirming that you have no candidates for
               ${
            location.state ?.name.split(",").includes(QualMapping.ENTRY)
              ? `Entry`
              : location.state ?.name.split(",").includes(QualMapping.GCSE)
                ? `GCSE`
                : `AS/A`
               }
              Level Physical Education for June 2024.`}
        </Typography>
        <Typography variant="body2">
          Please confirm your details below.
            </Typography>
          </>
        ) : (
          <>
        <Typography variant="body2" sx={{ mt: 2 }}>
          I confirm all the activities which candidates will be
              assessed against in this series have been declared on this form.
            </Typography>
      </>
      )}
      </Box>

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Name of person completing this form:*
          </Typography>
        <TextField
          variant={variant}
          margin={margin}
          name="submission_person_name"
          value={submission_person_name.value}
          onChange={handleChange}
          error={!!submission_person_name.error}
          helperText={submission_person_name.error}
          required={submission_person_name.required}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Date:*</Typography>

        <TextField
          type="date"
          value={new Date().toISOString().split("T")[0]}
          InputLabelProps={{
            shrink: true,
          }}
          disabled
        />
      </Grid>
    </Grid>
    <StepActions
      isError={isError}
      handleBack={handleBack}
      handleSave={handleSave}
      handleSubmit={onSubmit}
      isSubmit={true}
    />
    </>
  );
}
