import React from "react";
import { useState, useMemo } from "react";
import "./marksubmission.css";
import {
  MaterialReactTable,
  useMaterialReactTable,

} from 'material-react-table';
import { Button, Typography, Select, FormControl, MenuItem, TextField } from "@mui/material";
import {
  selectCandidates,
  handleChangeGcse,
  calculateMarks,
  calculateOverallMarkForGCSEAndEntry
} from "../../../store/reducers/candidateReducer";
import { selectFirstEvent } from "../../../store/reducers/eventReducer";
import { resetRestrictedActivity, selectRestrictedActivity } from "../../../store/reducers/restrictedActivityReducer";
import { checkStatus } from "./commonFuntions";
import { useSelector, useDispatch } from "react-redux";
import { selectActivity } from "../../../store/reducers/activityReducer";
import {
  renderActivitiesRows,
  renderEventRows,
  renderMarkRows,
  renderSexRows,
  renderEvidenceRows,
  renderActivityTypeRows,
} from "./GcseLevelCell";
import { useAuth } from "react-oidc-context";
import { inputStyleSecondary, tableInputStyle } from "../../../utils/Theme";
import { selectExceptionActivity } from "../../../store/reducers/exceptionalActivityReducer";

const GCSELevelTable = () => {
  const auth = useAuth();
  const acessToken = auth.user?.access_token;
  const dispatch = useDispatch();
  const { activities } = useSelector(selectActivity);
  const { candidates } = useSelector(selectCandidates);
  const { noEvents } = useSelector(selectExceptionActivity);
  const {
    first_events,
    first_event_row_id,
    second_event_row_id,
    third_event_row_id,
    second_events,
    third_events,
    first_event_row_type,
    second_event_row_type,
    third_event_row_type,
  } = useSelector(selectFirstEvent);
  const restrictedActivity = useSelector(selectRestrictedActivity);
  const rowData = [...candidates];

  const rows = rowData.map((row, i) => {
    const baseSubRow = [
      {
        activity_type_name: "Individual",
        activityType: "gcse_indiv_lvl_activities",
        sex: row.sex,
        candidate_number: row.candidate_number,
        candidate_name: row.candidate_name,
        ...row.gcse_indiv_lvl_activities,
        restrictedActivities: (restrictedActivity && restrictedActivity[i]) ? restrictedActivity[i] : null,
      },
      {
        activity_type_name: "Free",
        activityType: "gcse_free_choice_activities",
        sex: row.sex,
        candidate_number: row.candidate_number,
        candidate_name: row.candidate_name,
        ...row.gcse_free_choice_activities,
        restrictedActivities: (restrictedActivity && restrictedActivity[i]) ? restrictedActivity[i] : null,
      },
    ];

    return {
      ...row,
      ...row.gcse_team_lvl_activities,
      activity_type_name: "Team",
      activity_types: "Team, Individual, Free Choice",
      activityRows: [...baseSubRow],
    };
  });

  const [data, setData] = useState(rows)

  let firstEventOptions = []
  let secondEventOptions = []
  let thirdEventOptions = []

  const getNumber = (x) => {
    return isNaN(parseInt(x)) ? 0 : parseInt(x)
  }

  // Exclude user from selecting same activity or restricted activities (i.e. activities that cannot be combined)
  const includeActivity = (activity, row) => {
    let include = true;

    if (row.depth === 0) {
      // Top level row, check all subrows for potential duplicates
      row.subRows.forEach(x => {
        if (x.getValue('activity_id') === activity.activity_id)
          include = false;
        else if (activity.restricted_activities) {
          // Check for any restrictions
          activity.restricted_activities.forEach(y => {
            if (y === x.getValue('activity_id'))
              include = false;
          })
        }

      });
    }
    else {
      // Get parent, check parent and childrows for any duplicates
      const parent = row.getParentRow();
      if (parent.getValue('activity_id') === activity.activity_id)
        include = false;
      else if (activity.restricted_activities) {
        // Check for any restrictions
        activity.restricted_activities.forEach(y => {
          if (y === parent.getValue('activity_id'))
            include = false;
        })
      }

      parent.subRows.forEach(x => {
        if (x.index !== row.index && x.getValue('activity_id') === activity.activity_id)
          include = false;
        else if (activity.restricted_activities) {
          // Check for any restrictions
          activity.restricted_activities.forEach(y => {
            if (y === x.getValue('activity_id'))
              include = false;
          })
        }
      });
    }
    return include;
  }

  const resetMarks = (row, markNumber, includeSubRows) => {
    if (markNumber == 1) {
      row._valuesCache.first_event_mark = "";
      if (includeSubRows)
        row.subRows.forEach(x => { x._valuesCache.first_event_mark = "" })
    }

    if (markNumber <= 2) {
      row._valuesCache.second_event_mark = "";
      if (includeSubRows)
        row.subRows.forEach(x => { x._valuesCache.second_event_mark = "" })
    }

    if (markNumber <= 3) {
      row._valuesCache.third_event_mark = "";
      if (includeSubRows)
        row.subRows.forEach(x => { x._valuesCache.third_event_mark = "" })
    }

    // Update the activity mark
    row._valuesCache.total_for_activity = calculateMarks(row._valuesCache, { payload: { qual: "gcse" } });
    if (includeSubRows)
      row.subRows.forEach(x => { x._valuesCache.total_for_activity = calculateMarks(x._valuesCache, { payload: { qual: "gcse" } }); });
    // Update overall mark
    if (row.depth == 0)
      row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row._valuesCache, ...row.subRows.map(x => x._valuesCache)]);
    else
      row.getParentRow()._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row.getParentRow()._valuesCache, ...row.getParentRow().subRows.map(x => x._valuesCache)]);

  }

  // const checkStatus = ({ row }) => {
  //   if (
  //     row.gcse_team_lvl_activities.candidate_attendance_status === "A" &&
  //     row.gcse_indiv_lvl_activities.candidate_attendance_status === "A" &&
  //     row.gcse_free_choice_activities.candidate_attendance_status === "A"
  //   )
  //     return "Not available";
  //   else if (
  //     row.gcse_team_lvl_activities.candidate_attendance_status === "W" &&
  //     row.gcse_indiv_lvl_activities.candidate_attendance_status === "W" &&
  //     row.gcse_free_choice_activities.candidate_attendance_status === "W"
  //   )
  //     return "Withdrawn";
  //   else return "Present";
  // };
  const getRowHeight = params => 120;

  const isDisabled = row =>
    row.getValue('sex') === "Unknown" ||
    row.original.candidate_attendance_status === "A" ||
    row.original.candidate_attendance_status === "W";

  const onChangeEvent = (event, row, activities, id) => {
    data[row.index][event.name] = event.value;
    // params.row._valuesCache.first_event_id = event.value;

    dispatch(
      handleChangeGcse({
        event: { name: event.name, value: event.value },
        data: row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
        id: row.depth === 0 ? row.index : row.parentId,
        activityTypeCode: row.depth === 0 ? 'gcse_team_lvl_activities' : data[row.parentId].activityRows[row.index].activityType,
        qual: 'gcse'
      })
    );
  }
  const prepopulateEvents = (row, eventType) => {
    const acts = row.getValue('sex') == "M" ? activities.male_Activities : activities.female_Activities;
    let firstEvents = acts.filter(x => (x.activity_id == row.getValue("activity_id")))[0]?.first_events || []
    let secondLevelEvents = firstEvents.length && firstEvents.filter(x => (x.event_id == row.getValue("first_event_id")))[0]?.next_level_events || []
    let thirdLevelEvents = secondLevelEvents.length && secondLevelEvents.filter(x => (x.event_id == row.getValue("second_event_id")))[0]?.next_level_events || []

    if (row.getValue('sex') && row.getValue('activity_id')) {

      // Check and populate second level events
      if (row.getValue("first_event_id") && secondLevelEvents?.length == 1) {
        if (row.getValue('second_event_id') != secondLevelEvents[0].event_id) {
          // Populate second level event with new value
          row._valuesCache.second_event_id = secondLevelEvents[0].event_id;

          onChangeEvent({
            name: 'second_event_id',
            value: row._valuesCache.second_event_id
          }, row, activities, row.id)
        }
      }
      // Check and populate third level events
      if (row.getValue("second_event_id") && thirdLevelEvents?.length == 1) {
        if (row.getValue('second_event_id') != thirdLevelEvents[0].event_id) {
          // Populate second level event with new value
          row._valuesCache.third_event_id = thirdLevelEvents[0].event_id;

          onChangeEvent({
            name: 'third_event_id',
            value: row._valuesCache.third_event_id
          }, row, activities, row.id)
        }
      }
    }
  }

  const columns = useMemo(() => [
    {
      accessorKey: "candidate_number",
      enableEditing: false,
      header: "Candidate number",
      size: 120,
      muiTableBodyCellProps: ({ cell, row }) => ({
        className: (row.depth != 0) ? "candidate_number_childrow" : "candidate_number"
      }),
      Cell: params => {
        // Render the candidate number but make it invisible for childrows, this is to ensure the search still returns the info
        if (params.row.depth != 0)
          return <Typography sx={{ display: 'none' }}>{params.cell.getValue()}</Typography>;
        else
          return <Typography>{params.cell.getValue()}</Typography>;
      },
    },
    {
      accessorKey: "candidate_name",
      enableEditing: false,
      header: "Candidate name",
      size: 200,
      muiTableBodyCellProps: ({ cell, row }) => ({
        className: (row.depth != 0) ? "candidate_name_childrow" : "candidate_name"
      }),
      Cell: params => {
        // Render the candidate number but make it invisible for childrows, this is to ensure the search still returns the info
        if (params.row.depth != 0)
          return <Typography sx={{ display: 'none' }}>{params.cell.getValue()}</Typography>;
        else
          return <Typography>{params.cell.getValue()}</Typography>;
      },
    },
    {
      accessorKey: "sex",
      header: "Sex assigned at birth",
      size: 110,
      enableSorting: false,
      enableEditing: (row) => (row.depth === 0),
      muiTableBodyCellProps: ({ cell, row }) => ({
        className: (row.depth != 0) ? "sex_childrow" : "sex"
      }),
      editVariant: (row) => (row.depth === 0 ? "select" : ""),
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {

          data[row.index].sex = event.target.value
          row._valuesCache.sex = event.target.value;

          resetMarks(row, 1, true);

          // Clear all subrows as well
          row.subRows.forEach(x => {
            x._valuesCache.activity_id = "";
          });

          // Clear all subrows as well
          row.subRows.forEach(x => {
            x._valuesCache.role = "";
          });

          row._valuesCache.activity_id = "";
          row._valuesCache.role = "";


          dispatch(
            handleChangeGcse({
              event: {
                name: column.id,
                value: event.target.value
              },
              id: row.id,
              data: event.target.value === "M" ? activities.male_Activities : activities.female_Activities,
              qual: 'gcse'
            })
          )

        };

        return <Select
          sx={{ ...tableInputStyle, width: "100%" }}
          value={
            cell.getValue()
          }
          onChange={onChange}
        >
          <MenuItem key="M" value="M">Male</MenuItem>
          <MenuItem key="F" value="F">Female</MenuItem>
        </Select>;
      },

      Cell: params => {
        return <Typography>{params.row.depth == 0 ? (params.cell.getValue() === "F" ? "Female"
          : params.cell.getValue() === "M" ? "Male" : "") : ""
        }</Typography>;
      },
    },

    {
      accessorKey: "activity_type_name",
      enableSorting: false,
      header: "Type of activity",
      enableEditing: false,
      size: 100,
    },
    {
      accessorKey: "activity_id",
      enableSorting: false,
      header: "Activities",
      size: 180,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          data[row.index].activity_id = row._valuesCache.activity_id = event.target.value

          // Set whether filmed evidence is required (required for all off-site activities)
          const actList = row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities;
          row._valuesCache.filmed_evidence_to_be_provided = actList.filter(x => (x.activity_id == event.target.value))[0].activity_type == "on-site" ? "N" : "Y";

          resetMarks(row, 1, false);

          dispatch(
            handleChangeGcse({
              event: {
                name: column.id,
                value: event.target.value
              },
              data: row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
              id: row.depth == 0 ? row.index : row.parentId,
              activityTypeCode: row.depth === 0 ? 'gcse_team_lvl_activities' : data[row.parentId].activityRows[row.index].activityType,
              qual: 'gcse'
            })
          );
          data[row.index].activity_id = event.target.value
        };

        const actlist = (row.getValue('sex') === "F" && activities?.female_Activities
          ? activities?.female_Activities
            .filter(x => ((x.activity_type === row.getValue("activity_type_name")
              || row.getValue("activity_type_name") === "Free")
              && (includeActivity(x, row))))
            .map(x => ({ value: x.activity_id, label: x.activity_name }))
          : row.getValue('sex') === "M"
            && activities?.male_Activities
            ? activities?.male_Activities
              .filter(x => ((x.activity_type === row.getValue("activity_type_name")
                || row.getValue("activity_type_name") === "Free")
                && (includeActivity(x, row))))
              .map(x => ({ value: x.activity_id, label: x.activity_name }))
            : []
        );

        return <Select
          sx={tableInputStyle}
          value={cell.getValue()}
          onChange={onChange}
          disabled={isDisabled(row)}
        >
          {actlist.map(activity => {
            return (
              <MenuItem key={activity.value} value={activity.value}>
                {activity.label}
              </MenuItem>
            );
          })
          }
        </Select>;

      },

      Cell: params => {
        return (<Typography>{params.cell.getValue()}</Typography>
        )
      },
    },
    {
      accessorKey: "first_event_id",
      header: "First event/ skill/ position",
      size: 165,
      enableSorting: false,
      editVariant: 'select',
      editSelectOptions: (params) => {
        firstEventOptions = params.row.getValue('sex') === "F" &&
          params.row.getValue('activity_id') &&
          activities?.female_Activities &&
          activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0].first_events &&
          activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0].first_events.filter(x => (x.event_sex_restriction == "None" || x.event_sex_restriction == "Female"))
          ? activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0].first_events.filter(x => (x.event_sex_restriction == "None" || x.event_sex_restriction == "Female")).map(x => ({ value: x.event_id, label: x.event_display_name }))
          : params.row.getValue('sex') === "M" &&
            params.row.getValue('activity_id') &&
            activities?.male_Activities &&
            activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0].first_events &&
            activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0].first_events.filter(x => (x.event_sex_restriction == "None" || x.event_sex_restriction == "Male"))
            ? activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0].first_events.filter(x => (x.event_sex_restriction == "None" || x.event_sex_restriction == "Male")).map(x => ({ value: x.event_id, label: x.event_display_name }))
            : []
        return firstEventOptions
      },
      muiEditTextFieldProps: (params) => ({
        id: "first_" + params.row.id,
        select: true,
        disabled: !params.row.getValue('sex') ||
          !params.row.getValue('activity_id') ||
          (params.row.getValue('sex') === "F" && activities?.female_Activities && !activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events) ||
          (params.row.getValue('sex') === "M" && activities?.male_Activities && !activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events),

        onChange: (event) => {
          params.row._valuesCache.first_event_id = event.target.value;
          onChangeEvent(event.target, params.row, activities, 1)
          prepopulateEvents(params.row);


          // An exception case, Athletics and Pole Vaulting is the only off-site athletics event so the filming is required
          if (params.row._valuesCache.activity_id == "4" && params.row._valuesCache.first_event_id == "35")
            params.rows._valuesCache.filmed_evidence_to_be_provided = "Y";

        }
      }),
    },
    {
      accessorKey: "first_event_mark",
      header: "Mark",
      size: 85,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          // Dirty hack to prevent scrolling the table changing the value of this field
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 20)
            event.target.value = 20;
          if (event.target.value < 0)
            event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleChangeGcse({
              event: { name: column.id, value: event.target.value },
              data: row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
              id: row.depth == 0 ? row.index : row.parentId,
              activityTypeCode: row.depth === 0 ? 'gcse_team_lvl_activities' : data[row.parentId].activityRows[row.index].activityType,
              qual: 'gcse'
            })
          );

          // Update the activity mark
          row._valuesCache.total_for_activity = calculateMarks(row._valuesCache, { payload: { qual: "gcse" } });

          // Update overall mark
          if (row.depth == 0)
            row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row._valuesCache, ...row.subRows.map(x => x._valuesCache)]);
          else
            row.getParentRow()._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row.getParentRow()._valuesCache, ...row.getParentRow().subRows.map(x => x._valuesCache)]);

        }

        return <TextField onChange={onChange}
          value={cell.getValue()}
          type="number"
          sx={{
            ...{ ...tableInputStyle },
            width: "100%"
          }}
          disabled={!row.getValue('sex') ||
            !row.getValue('activity_id')}
          placeholder="Mark" />;
      },

    },
    {
      accessorKey: "second_event_id",
      header: "Second event/ skill/ position",
      size: 165,
      enableSorting: false,
      editVariant: 'select',
      editSelectOptions: (params) => {
        secondEventOptions = params.row.getValue('sex') === "F" &&
          params.row.getValue('activity_id') &&
          params.row.getValue('first_event_id') &&
          activities?.female_Activities &&
          activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events
          ? activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events?.filter(x => (x.event_sex_restriction === "None" || x.event_sex_restriction == "Female")).map(x => ({ value: x.event_id, label: x.event_display_name }))
          : params.row.getValue('sex') === "M" &&
            params.row.getValue('activity_id') &&
            params.row.getValue('first_event_id') &&
            activities?.male_Activities &&
            activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events
            ? activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events?.filter(x => (x.event_sex_restriction === "None" || x.event_sex_restriction == "Male")).map(x => ({ value: x.event_id, label: x.event_display_name }))
            : []
        return secondEventOptions
      },
      muiEditTextFieldProps: (params) => ({
        //    select: true,
        id: "second_" + params.row.id,
        value: secondEventOptions.length === 1 ? secondEventOptions[0].value : params.row.getValue("second_event_id"),
        disabled: !params.row.getValue('sex') ||
          !params.row.getValue('activity_id') ||
          !params.row.getValue('first_event_id') ||
          (params.row.getValue('sex') === "F" && activities?.female_Activities && !activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events) ||
          (params.row.getValue('sex') === "M" && activities?.male_Activities && !activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events) ||
          (params.row.getValue('sex') === "M" && activities?.male_Activities && (!activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events || !activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events)) ||
          (params.row.getValue('sex') === "F" && activities?.female_Activities && (!activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events || !activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events))
        ,

        onChange: (event) => {
          onChangeEvent(event.target, params.row, activities, 2)
          prepopulateEvents(params.row);
        }
      }),
    },
    {
      accessorKey: "second_event_mark",
      header: "Mark",
      size: 85,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          // Dirty hack to prevent scrolling the table changing the value of this field
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 20)
            event.target.value = 20;
          if (event.target.value < 0)
            event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleChangeGcse({
              event: { name: column.id, value: event.target.value },
              data: row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
              id: row.depth == 0 ? row.index : row.parentId,
              activityTypeCode: row.depth === 0 ? 'gcse_team_lvl_activities' : data[row.parentId].activityRows[row.index].activityType,
              qual: 'gcse'
            })
          );

          // Update the activity mark
          row._valuesCache.total_for_activity = calculateMarks(row._valuesCache, { payload: { qual: "gcse" } });
          // Update overall mark
          if (row.depth == 0)
            row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row._valuesCache, ...row.subRows.map(x => x._valuesCache)]);
          else
            row.getParentRow()._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row.getParentRow()._valuesCache, ...row.getParentRow().subRows.map(x => x._valuesCache)]);

        }

        return <TextField onChange={onChange}
          value={cell.getValue()}
          type="number"
          sx={{
            ...{ ...tableInputStyle },
            width: "100%"
          }}
          disabled={!row.getValue('sex') ||
            !row.getValue('activity_id') ||
            !row.getValue('first_event_id') ||
            !row.getValue('second_event_id') ||
            (row.getValue('sex') === "F" && activities?.female_Activities && !activities?.female_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events) ||
            (row.getValue('sex') === "M" && activities?.male_Activities && !activities?.male_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events) ||
            (row.getValue('sex') === "M" && activities?.male_Activities && (!activities?.male_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events || !activities?.male_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === row.getValue('first_event_id')))[0]?.next_level_events)) ||
            (row.getValue('sex') === "F" && activities?.female_Activities && (!activities?.female_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events || !activities?.female_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === row.getValue('first_event_id')))[0]?.next_level_events))
          }
          placeholder="Mark" />;
      },

    },
    {
      accessorKey: "third_event_id",
      header: "Third event/ skill/ position",
      size: 165,
      enableSorting: false,
      editVariant: 'select',
      editSelectOptions: (params) => {
        thirdEventOptions = params.row.getValue('sex') === "F" &&
          params.row.getValue('activity_id') &&
          params.row.getValue('first_event_id') &&
          params.row.getValue('second_event_id') &&
          activities?.female_Activities &&
          activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events &&
          activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id === params.row.getValue('second_event_id')))[0]?.next_level_events
          ? activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id === params.row.getValue('second_event_id')))[0]?.next_level_events?.filter(x => (x.event_sex_restriction === "None" || x.event_sex_restriction == "Female")).map(x => ({ value: x.event_id, label: x.event_display_name }))
          : params.row.getValue('sex') === "M" &&
            params.row.getValue('activity_id') &&
            params.row.getValue('first_event_id') &&
            params.row.getValue('second_event_id') &&
            activities?.male_Activities &&
            activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events &&
            activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id === params.row.getValue('second_event_id')))[0]?.next_level_events
            ? activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id === params.row.getValue('second_event_id')))[0]?.next_level_events?.filter(x => (x.event_sex_restriction === "None" || x.event_sex_restriction == "Male")).map(x => ({ value: x.event_id, label: x.event_display_name }))
            : []
        return thirdEventOptions
      },
      muiEditTextFieldProps: (params) => ({
        //    select: true,
        id: "third_" + params.row.id,
        value: thirdEventOptions.length === 1 ? thirdEventOptions[0].value : params.row.getValue("third_event_id"),
        disabled: !params.row.getValue('sex') ||
          !params.row.getValue('activity_id') ||
          !params.row.getValue('first_event_id') ||
          !params.row.getValue('second_event_id') ||
          (params.row.getValue('sex') === "F" && !activities?.female_Activities) ||
          (params.row.getValue('sex') === "M" && !activities?.male_Activities) ||
          (params.row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events) ||
          (params.row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events) ||
          (params.row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events) ||
          (params.row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events) ||
          (params.row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id === params.row.getValue('second_event_id')))[0]?.next_level_events) ||
          (params.row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id == params.row.getValue('activity_id')))[0]?.first_events.filter(x => (x.event_id === params.row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id === params.row.getValue('second_event_id')))[0]?.next_level_events)
        ,
        onChange: (event) => {
          data[params.row.index].third_event_id = event.target.value
          dispatch(
            handleChangeGcse({
              event: { name: event.target.name, value: event.target.value },
              data: params.row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
              id: params.row.depth === 0 ? params.row.index : params.row.parentId,
              activityTypeCode: params.row.depth === 0 ? 'gcse_team_lvl_activities' : data[params.row.parentId].activityRows[params.row.index].activityType,
              qual: 'gcse'
            })
          );
        }
      }),

    },
    {
      accessorKey: "third_event_mark",
      header: "Mark",
      size: 85,
      enableSorting: false,
      Edit: ({ cell, column, row, table }) => {
        const onChange = (event) => {
          // Dirty hack to prevent scrolling the table changing the value of this field
          if (!event.nativeEvent.inputType) {
            event.target.value = event.target.oldValue;
            return;
          }

          event.target.oldValue = event.target.value;

          // Set the maxmimum and minimum value for the field
          if (event.target.value > 20)
            event.target.value = 20;
          if (event.target.value < 0)
            event.target.value = 0;

          event.target.value = Math.round(event.target.value);

          row._valuesCache[column.id] = event.target.value;

          dispatch(
            handleChangeGcse({
              event: { name: column.id, value: event.target.value },
              data: row.getValue('sex') === "M" ? activities.male_Activities : activities.female_Activities,
              id: row.depth == 0 ? row.index : row.parentId,
              activityTypeCode: row.depth === 0 ? 'gcse_team_lvl_activities' : data[row.parentId].activityRows[row.index].activityType,
              qual: 'gcse'
            })
          );

          // Update the activity mark
          row._valuesCache.total_for_activity = calculateMarks(row._valuesCache, { payload: { qual: "gcse" } });
          // Update overall mark
          if (row.depth == 0)
            row._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row._valuesCache, ...row.subRows.map(x => x._valuesCache)]);
          else
            row.getParentRow()._valuesCache.overall_mark = calculateOverallMarkForGCSEAndEntry([row.getParentRow()._valuesCache, ...row.getParentRow().subRows.map(x => x._valuesCache)]);

        }

        return <TextField onChange={onChange}
          value={cell.getValue()}
          type="number"
          sx={{
            ...{ ...tableInputStyle },
            width: "100%"
          }}
          disabled={!row.getValue('sex') ||
            !row.getValue('activity_id') ||
            !row.getValue('first_event_id') ||
            !row.getValue('second_event_id') ||
            !row.getValue('third_event_id') ||
            (row.getValue('sex') === "F" && !activities?.female_Activities) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities) ||
            (row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events) ||
            (row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === row.getValue('first_event_id')))[0]?.next_level_events) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events?.filter(x => (x.event_id === row.getValue('first_event_id')))[0]?.next_level_events) ||
            (row.getValue('sex') === "F" && !activities?.female_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events.filter(x => (x.event_id === row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id === row.getValue('second_event_id')))[0]?.next_level_events) ||
            (row.getValue('sex') === "M" && !activities?.male_Activities.filter(x => (x.activity_id == row.getValue('activity_id')))[0]?.first_events.filter(x => (x.event_id === row.getValue('first_event_id')))[0]?.next_level_events.filter(x => (x.event_id === row.getValue('second_event_id')))[0]?.next_level_events)
          }
          placeholder="Mark" />;
      },

    },
    {
      accessorKey: "total_for_activity",
      header: "Total activity mark",
      size: 85,
      enableSorting: false,
      enableEditing: false,
      Cell: (params) => {
        return (<Typography>{params.cell.getValue()}</Typography>)
      },

    },
    {
      accessorKey: "overall_mark",
      header: "Overall activity mark",
      size: 85,
      muiTableBodyCellProps: ({ cell, row }) => ({
        className: (row.depth != 0) ? "overallmark_childrow" : "overallmark"
      }),
      enableSorting: false,
      enableEditing: false,
      sortable: false,
      Cell: params => {
        return (<Typography>{
          params.row.original.candidate_attendance_status === "W" ? "Withdrawn" :
            params.row.original.candidate_attenance_status === "A" ? "Not Available" :
              !params.row.parentId ? params.cell.getValue() : ""
        }</Typography>)
      },

    },
    {
      accessorKey: "filmed_evidence_to_be_provided",
      header: "Filmed evidence must be provided",
      size: 105,
      enableEditing: false,
      enableSorting: false,
      Cell: params => {
        return (<Typography>{params.row.getValue('filmed_evidence_to_be_provided') === "N" ? "No" : "Yes"}</Typography>)
      },
    },

  ]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnPinning: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnResizing: true,
    enableHiding: false,
    layoutMode: 'grid-no-grow', //constant column widths
    initialState: {
      columnPinning: { left: ['candidate_number', 'candidate_name'] },
      expanded: true,
      density: 'compact',
      pagination: { pageSize: 9, pageIndex: 1 }
    },
    enableExpandAll: false, //hide expand all double arrow in column header
    enableExpanding: true,
    filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
    getSubRows: (row) => row.activityRows, //default
    enableEditing: true,
    enableKeyboardShortcuts: false, // With our custom UI elements, set this to false to prevent underlying table cells getting focus.
    editDisplayMode: 'table',
    muiPaginationProps: {
      rowsPerPageOptions: [9, 18, 36, 72, 99],
    },
    muiTableProps: {
      className: 'GCSETable'
    },
    muiTableBodyRowProps: ({ row }) => {
      return (row.original.candidate_attendance_status === "W" || row.original.candidate_attendance_status === "A" ? {
        //conditionally style expanded rows
        sx: {
          backgroundColor: '#ccc'
        },

      } : {}, { hover: false })
    },
    muiTableBodyCellProps:
    {
      sx: {
        paddingLeft: '5px',
        padding: 0,
        textWrap: "wrap",
      }
    },

  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
  // return (
  //   // <div style={{ height: 700, width: "100%" }}>
  //   <DataGrid
  //     sx={{
  //       "& .MuiDataGrid-cellContent": {
  //         textWrap: "wrap",
  //       },
  //     }}
  //     rows={rows}
  //     columns={columns}
  //     initialState={{
  //       pagination: {
  //         paginationModel: { page: 0, pageSize: 10 },
  //       },
  //     }}
  //     autoHeight
  //     checkboxSelection={false}
  //     onSelectionModelChange={() => {}}
  //     getRowHeight={getRowHeight}
  //     disableColumnMenu
  //   />
  //   // </div>
  // );
};

export default GCSELevelTable;
