import React from "react";
import { Typography, Box } from "@mui/material";
import StepActionsMarksSubmission from "../markSubmissionSteps/StepActionsMarksSubmission";

import { handleBack, selectPemifData } from "../../store/reducers/pemifReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCandidates } from "../../store/reducers/candidateReducer";
import { useLocation } from "react-router-dom";
import { QualMapping } from "../../utils/constants";

export default function Confirm() {
  const dispatch = useDispatch();

  const { session_name, session_year, isAckPage } =
    useSelector(selectPemifData);
  const { candidate_details } = useSelector(selectCandidates);
  const location = useLocation();
  return (
    <>
      <Box sx={{ mb: 1, mt: "-35px !important", height: "60vh !important" }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: "15px !important",
              sm: "20px !important",
              md: "20px !important",
            },
          }}
        >
          {location.state?.name === QualMapping.ENTRY
            ? `'Entry Level' Physical Education`
            : location.state?.name === QualMapping.GCSE
            ? `'GCSE' Physical Education`
            : location.state?.name === QualMapping.ALEVEL
            ? `'A Level' Physical Education`
            : `'AS Level' Physical Education`}{" "}
          activity marks submission '{session_name} {session_year}'
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            mb: 2,
            fontWeight: "bold",
            fontSize: { xs: "14px !important", sm: "16px !important" },
          }}
        >
          {isAckPage ? "Submission confirmation" : "Confirmation"}
        </Typography>
        <Box sx={{ maxWidth: "58rem" }}>
          {isAckPage ? (
            <Typography>
              <Typography
                variant="body2"
                sx={{ lineHeight: "1.8", marginTop: "1%" }}
              >
                Thank you for submitting your activity marks.
              </Typography>
              <Typography
                variant="body2"
                sx={{ lineHeight: "1.8", marginTop: "1%" }}
              >
                You can download the marks you have submissed by clicking the button below.
              </Typography>
            </Typography>
          ) : (
            <Typography>
              <Typography
                variant="body2"
                sx={{ lineHeight: "1.8", marginTop: "1%" }}
              >
                Are you sure you want to submit your activity marks?
              </Typography>
              <Typography
                variant="body2"
                sx={{ lineHeight: "1.8", marginTop: "1%" }}
              >
                By submitting this marks, you are confirming all the details you have entered are correct.
              </Typography>
            </Typography>
          )}

        </Box>
      </Box>

      <StepActionsMarksSubmission
        isError={() => !candidate_details.pe_centre_contact_name}
        handleBack={() => dispatch(handleBack())}
        handleSave={() => {}}
        handleSubmit={() => {}}
        isSubmit={true}
      />
    </>
  );
}
