export const checkStatus = (row, qual) => {
  if (qual === "gcse") {
    if (
      row.gcse_team_lvl_activities.candidate_attendance_status === "A" &&
      row.gcse_indiv_lvl_activities.candidate_attendance_status === "A" &&
      row.gcse_free_choice_activities.candidate_attendance_status === "A"
    )
      return "Not available";
    else if (
      row.gcse_team_lvl_activities.candidate_attendance_status === "W" &&
      row.gcse_indiv_lvl_activities.candidate_attendance_status === "W" &&
      row.gcse_free_choice_activities.candidate_attendance_status === "W"
    )
      return "Withdrawn";
    else return "Present";
  } else if (qual === "entry") {
    if (
      row.entry_lvl_team_activities.candidate_attendance_status === "A" &&
      row.entry_lvl_indiv_activities.candidate_attendance_status === "A" &&
      row.entry_lvl_free_choice_one_activities.candidate_attendance_status ===
        "A" &&
      row.entry_lvl_free_choice_two_activities.candidate_attendance_status ===
        "A" &&
      row.entry_lvl_perf_analysis.candidate_attendance_status === "A"
    )
      return "Not available";
    else if (
      row.entry_lvl_team_activities.candidate_attendance_status === "W" &&
      row.entry_lvl_indiv_activities.candidate_attendance_status === "W" &&
      row.entry_lvl_free_choice_one_activities.candidate_attendance_status ===
        "W" &&
      row.entry_lvl_free_choice_two_activities.candidate_attendance_status ===
        "W" &&
      row.entry_lvl_perf_analysis.candidate_attendance_status === "W"
    )
      return "Withdrawn";
    else return "Present";
  }
};
