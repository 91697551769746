import React, { useContext, useEffect } from "react";
import StepActions from "../common/StepActions";
import {
  Typography,
  Box,
  Grid,
  Table,
  TableContainer,
  Divider,
  TextareaAutosize,
  Link,
} from "@mui/material";
import "./FifthStep.css";
import { useLocation } from "react-router-dom";
import {
  QualMapping,
  aLevelColumns,
  aLevelVenueColumns,
  entryLevelColumns,
  entryLevelVenueColumns,
  gcseLevelColumns,
  gcseLevelVenueColumns,
} from "../../utils/constants.js";
import { AppTableBody, AppTableHead } from "../common/Table.jsx";
import CommonText from "../common/FifthStepCommonText.jsx";
import { MainContext } from "../../context/Context.jsx";

export default function FifthStep() {
  const location = useLocation();
  const {
    formValues,
    handleBack,
    handleNext,
    handleSave,
    handleFieldChange,
    handleChange,
  } = useContext(MainContext);
  const {
    level,
    centre_host_venue_indicator,
    a_level_activities,
    as_level_activities,
    gcse_activities_individual,
    gcse_activities_team,
    entrylvl_activities_team,
    entrylvl_activities_individual,
    facilities_addl_info,
    moderator_addl_info,
    moderation_type
  } = formValues;
  const onBack = () => {
    location.state?.name === QualMapping.ENTRY
      ? handleBack(formValues?.moderation_type.value)
      : handleBack();
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {(level.value === "A Level Only" || level.value === "AS and A Level") &&
        location.state?.name !== QualMapping.GCSE &&
        location.state?.name !== QualMapping.ENTRY && (
          <div>
            <Box sx={{ mt: 5, mb: 1 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  If you need any help in completing this form, please contact{" "}
                  {/* <Link
              href="https://support.ocr.org.uk/"
              color="#0000e8"
              underline="always"
            >
              {"support@ocr.org.uk"}{" "}
            </Link> */}
            <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }} />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  A Level
                </Typography>
                <CommonText level="A" indicator={centre_host_venue_indicator} />
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <TableContainer className="TableForm">
                <Table
                  sx={{ minWidth: 600 }}
                  aria-label="simple table"
                  style={{ tableLayout: "fixed" }}
                >
                  <AppTableHead
                    data={
                      centre_host_venue_indicator === "Y"
                        ? aLevelColumns
                        : aLevelVenueColumns
                    }
                  />
                  <AppTableBody
                    data={a_level_activities}
                    handleFieldChange={handleFieldChange}
                    venue={centre_host_venue_indicator}
                    activities="a_level_activities"
                  />
                </Table>
              </TableContainer>
            </Grid>

            <Divider variant="fullWidth" sx={{ mt: 3, mb: 1 }} />
          </div>
        )}
      {(level.value === "AS Level Only" || level.value === "AS and A Level") &&
        location.state?.name !== QualMapping.GCSE &&
        location.state?.name !== QualMapping.ENTRY && (
          <div>
            <Box sx={{ mt: 5, mb: 1 }}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  AS Level
                </Typography>
                <CommonText
                  level="AS"
                  indicator={centre_host_venue_indicator}
                />
              </Grid>
            </Box>
            <Grid container spacing={2}>
              <TableContainer className="TableForm">
                <Table
                  sx={{ minWidth: 600 }}
                  aria-label="simple table"
                  style={{ tableLayout: "fixed" }}
                >
                  <AppTableHead
                    data={
                      centre_host_venue_indicator === "Y"
                        ? aLevelColumns
                        : aLevelVenueColumns
                    }
                  />
                  <AppTableBody
                    data={as_level_activities}
                    handleFieldChange={handleFieldChange}
                    venue={centre_host_venue_indicator}
                    activities="as_level_activities"
                  />
                </Table>
              </TableContainer>
            </Grid>

            <Divider variant="fullWidth" sx={{ mt: 3, mb: 1 }} />
          </div>
        )}
      {location.state?.name === QualMapping.GCSE && (
        <div>
          <Box sx={{ mt: 5, mb: 1 }}>
            <Grid item xs={12}>
              <CommonText
                level="GCSE"
                indicator={centre_host_venue_indicator}
              />
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                If you need any help in completing this form, please contact{" "}
                {/* <Link
              href="https://support.ocr.org.uk/"
              color="#0000e8"
              underline="always"
            >
              {"support@ocr.org.uk"}{" "}
            </Link> */}
            <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Individual activities
              </Typography>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <TableContainer className="TableForm">
              <Table
                sx={{ minWidth: 600 }}
                aria-label="simple table"
                style={{ tableLayout: "fixed" }}
              >
                <AppTableHead
                  data={
                    centre_host_venue_indicator === "Y"
                      ? gcseLevelColumns
                      : gcseLevelVenueColumns
                  }
                />
                <AppTableBody
                  data={gcse_activities_individual}
                  handleFieldChange={handleFieldChange}
                  venue={centre_host_venue_indicator}
                  location={location.state?.name}
                  activities="gcse_activities_individual"
                />
              </Table>
            </TableContainer>
          </Grid>
          <Divider variant="fullWidth" />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Team activities
            </Typography>
            <TableContainer className="TableForm">
              <Table
                sx={{ minWidth: 600 }}
                aria-label="simple table"
                style={{ tableLayout: "fixed" }}
              >
                <AppTableHead
                  data={
                    centre_host_venue_indicator === "Y"
                      ? gcseLevelColumns
                      : gcseLevelVenueColumns
                  }
                />
                <AppTableBody
                  data={gcse_activities_team}
                  handleFieldChange={handleFieldChange}
                  venue={centre_host_venue_indicator}
                  location={location.state?.name}
                  activities="gcse_activities_team"
                />
              </Table>
            </TableContainer>
          </Grid>

          <Divider variant="fullWidth" sx={{ mb: 2 }} />
        </div>
      )}
      {location.state?.name === QualMapping.ENTRY && (
        <div>
          <Box sx={{ mt: 5, mb: 1 }}>
            <Grid item xs={12}>
              <CommonText level="ENTRY" indicator={moderation_type.value}/>
              <Typography variant="subtitle2" sx={{ mb: 2 }}>
                If you need any help in completing this form, please contact{" "}
                {/* <Link
              href="https://support.ocr.org.uk/"
              color="#0000e8"
              underline="always"
            >
              {"support@ocr.org.uk"}{" "}
            </Link> */}
            <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
              </Typography>
            </Grid>
          </Box>
          <Divider variant="fullWidth" />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Team activities
            </Typography>
            <TableContainer className="TableForm">
              <Table
                sx={{ minWidth: 600 }}
                aria-label="simple table"
                style={{ tableLayout: "fixed" }}
              >
                <AppTableHead
                  data={
                    formValues.moderation_type.value === "Visiting"
                      ? entryLevelColumns
                      : entryLevelVenueColumns
                  }
                />
                <AppTableBody
                  data={entrylvl_activities_team}
                  handleFieldChange={handleFieldChange}
                  venue={formValues.moderation_type.value}
                  location={location.state?.name}
                  activities="entrylvl_activities_team"
                />
              </Table>
            </TableContainer>
          </Grid>
          <Divider variant="fullWidth" />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Individual activities
            </Typography>
            <TableContainer className="TableForm">
              <Table
                sx={{ minWidth: 600 }}
                aria-label="simple table"
                style={{ tableLayout: "fixed" }}
              >
                <AppTableHead
                  data={
                    formValues.moderation_type.value === "Visiting"
                      ? entryLevelColumns
                      : entryLevelVenueColumns
                  }
                />
                <AppTableBody
                  data={entrylvl_activities_individual}
                  handleFieldChange={handleFieldChange}
                  venue={formValues.moderation_type.value}
                  location={location.state?.name}
                  activities="entrylvl_activities_individual"
                />
              </Table>
            </TableContainer>
          </Grid>

          <Divider variant="fullWidth" sx={{ mb: 2 }} />
        </div>
      )}
      {centre_host_venue_indicator === "Y" &&
        location.state?.name !== QualMapping.ENTRY && (
          <>
            <Divider variant="fullWidth" sx={{ mt: 3, mb: 2 }} />{" "}
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                Is there anything we need to know about your facilities?
              </Typography>

              <TextareaAutosize
                minRows={4}
                maxRows={4}
                style={{ height: "5rem", width: "50%" }}
                value={facilities_addl_info.value}
                name="facilities_addl_info"
                onChange={handleChange}
              />
              <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            </Grid>
          </>
        )}
      {(centre_host_venue_indicator === "Y" ||
        location.state?.name === QualMapping.ENTRY) && (
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 1 }}>
            Further information to be shared with your moderator:
          </Typography>

          <TextareaAutosize
            minRows={4}
            maxRows={4}
            style={{ height: "5rem", width: "50%" }}
            value={moderator_addl_info.value}
            name="moderator_addl_info"
            onChange={handleChange}
          />
        </Grid>
      )}
      <StepActions
        isError={() => false}
        handleBack={onBack}
        handleNext={handleNext}
        handleSave={handleSave}
      />
    </>
  );
}
