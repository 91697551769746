import React, { useContext } from "react";
import FirstStep from "../steps/FirstStep";
import SecondStep from "../steps/SecondStep";
import Confirm from "../steps/Confirm";
import Success from "../steps/Success";
import { useLocation } from "react-router-dom";
import ThirdStep from "../steps/ThirdStep";
import FourthStep from "../steps/FourthStep";
import FifthStep from "../steps/FiftStep";
import { Container, StepLabel, Step, Stepper } from "@mui/material";
import "./StepForm.css";
import SixthStep from "../steps/SixthStep";
import { MainContext } from "../../context/Context";
import { QualMapping, labels, entryLabels } from "../../utils/constants";

const handleSteps = (step, qualification) => {
  switch (step) {
    case 0:
      return <FirstStep />;
    case 1:
      return <SecondStep />;
    case 2:
      return qualification === QualMapping.ENTRY ? (
        <SixthStep />
      ) : (
        <ThirdStep />
      );
    case 3:
      return qualification === QualMapping.ENTRY ? (
        <ThirdStep />
      ) : (
        <FourthStep />
      );
    case 4:
      return qualification === QualMapping.ENTRY ? (
        <FourthStep />
      ) : (
        <FifthStep />
      );
    case 5:
      return qualification === QualMapping.ENTRY ? <FifthStep /> : <Confirm />;
    case 6:
      return <Confirm />;
    default:
      throw new Error("Unknown step");
  }
};

function StepForm() {
  const location = useLocation();
  const { activeStep, formValues } = useContext(MainContext);
  const { expected_entries_indicator } = formValues;
  return (
    <>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        {activeStep === 1 && expected_entries_indicator === "N" ? (
          <Confirm />
        ) : (location.state?.name !== QualMapping.ENTRY &&
            activeStep === labels.length) ||
          (location.state?.name === QualMapping.ENTRY &&
            activeStep === entryLabels.length) ? (
          <Success />
        ) : (
          <>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
              <Stepper activeStep={activeStep} sx={{ py: 3 }} alternativeLabel>
                {location.state?.name === QualMapping.ENTRY
                  ? entryLabels.map(label => (
                      <Step key={label}>
                        <StepLabel></StepLabel>
                      </Step>
                    ))
                  : labels.map(label => (
                      <Step key={label}>
                        <StepLabel></StepLabel>
                      </Step>
                    ))}
              </Stepper>
            </Container>
            {handleSteps(activeStep, location.state?.name)}
          </>
        )}
      </Container>
    </>
  );
}
export default StepForm;
