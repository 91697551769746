import React, { useContext } from "react";
import StepActions from "../common/StepActions";
import { Typography, Box, Link, List, ListItem } from "@mui/material";
import { MainContext } from "../../context/Context";
export default function Success() {
  const { formValues } = useContext(MainContext);
  const { expected_entries_indicator } = formValues;
  return (
    <>
      <Box sx={{ mt: 10, mb: 10 }}>
        <Typography variant="h5" sx={{ py: 4 }}>
          Form submitted successfully
        </Typography>
        {expected_entries_indicator === "N" ? (
          <>
            <Typography component="p">
              Thank you for submitting your Visit arrangement form for June
              2024, confirming your are not making an entry this year. If your
              situation changes and you do intend to make an entry, please
              contact{" "}
              {/* <Link
                href="https://support.ocr.org.uk/"
                color="#0000e8"
                underline="always"
              >
                {"support@ocr.org.uk"}{" "}
              </Link> */}
              <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
            </Typography>
          </>
        ) : (
          <>
            <Typography component="p">
              Thank you for submitting your Visit arrangement form for June
              2024. Your moderator will be in touch to arrange your visit. If
              you need to amend the details on your form, please contact{" "}
              {/* <Link
              href="https://support.ocr.org.uk/"
              color="#0000e8"
              underline="always"
            >
              {"support@ocr.org.uk"}{" "}
            </Link> */}
            <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
            </Typography>
            <Typography component="body2">Please note:</Typography>
            <List sx={{ listStyleType: "disc", mx: 2 }}>
              <ListItem
                sx={{
                  display: "list-item",
                  fontSize: "0.8rem",
                  padding: "0px",
                }}
              >
                All final marks and filmed evidence must be submitted by 15
                March.
              </ListItem>
              <ListItem
                sx={{
                  display: "list-item",
                  fontSize: "0.8rem",
                  padding: "0px",
                }}
              >
                All final marks for visits arranged before 25 March must be
                submitted at least 10 working days before the visit.
              </ListItem>
              <ListItem
                sx={{
                  display: "list-item",
                  fontSize: "0.8rem",
                  padding: "0px",
                }}
              >
                Overall component marks must be submitted via Interchange or EDI
                - see the NEA page for more details.
              </ListItem>
              <ListItem
                sx={{
                  display: "list-item",
                  fontSize: "0.8rem",
                  padding: "0px",
                }}
              >
                Marks for each activity must be submitted using the activity
                marks submission form.
              </ListItem>
            </List>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Not submitting marks by the deadline puts students at risk of not
              receiving their results on results day and may prompt a
              malpractice investigation.
            </Typography>
            <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
              Please refer to the Guide to NEA on the OCR websites if you need
              more information about filming requirements and how to prepare for
              your moderation visit.
            </Typography>
          </>
        )}
      </Box>
      <StepActions isSuccess={true} />
    </>
  );
}
