import {
  Checkbox,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { QualMapping } from "../../utils/constants";
const label = { inputProps: { "aria-label": "Checkbox" } };
export const AppTableHead = ({ data }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {data.map(c => (
          <TableCell key={c} align="center">
            {c}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const AppTableBody = ({
  data,
  handleFieldChange,
  venue,
  location,
  activities,
}) => {
  return (
    <TableBody>
      {data.map((row, i) => (
        <TableRow key={row.name} sx={{ "td, th": { border: 1 } }}>
          <TableCell component="th" sx={{fontSize:"0.74rem"}} scope="row">
            {row.name}
          </TableCell>

          <TableCell align="center" scope="row">
            <TextField
              type="number"
              name="males"
              className={row.males}
              onChange={e => handleFieldChange(e, i, activities)}
              value={row.males}
              variant="outlined"
            />
          </TableCell>

          <TableCell align="center">
            <TextField
              type="number"
              name="females"
              className={row.females}
              value={row.females}
              variant="outlined"
              onChange={e => handleFieldChange(e, i, activities)}
            />
          </TableCell>

          {location !== QualMapping.ENTRY && (
            <>
              <TableCell align="center">
                <Checkbox
                  {...label}
                  name="off_site"
                  checked={row.off_site.ticked}
                  disabled={!row.off_site.enabled}
                  value={row.off_site.ticked?'on':"off"}
                  onChange={e => handleFieldChange(e, i, activities)}
                />
              </TableCell>

              {location !== QualMapping.GCSE && (
                <TableCell align="center">
                  <Checkbox
                    {...label}
                    name="filmed"
                    checked={row.filmed.ticked}
                    disabled={!row.filmed.enabled}
                    value={row.filmed.ticked?'on':"off"}
                    onChange={e => handleFieldChange(e, i, activities)}
                  />
                </TableCell>
              )}

              {venue === "Y" && (
                <>
                  <TableCell align="center">
                    <Checkbox
                      {...label}
                      name="outdoor"
                      checked={row.outdoor.ticked}
                      disabled={!row.outdoor.enabled}
                      value={row.outdoor.ticked?'on':"off"}
                      onChange={e => handleFieldChange(e, i, activities)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Checkbox
                      {...label}
                      name="indoor"
                      checked={row.indoor.ticked}
                      disabled={!row.indoor.enabled}
                      value={row.indoor.ticked?'on':"off"}
                      onChange={e => handleFieldChange(e, i, activities)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="number"
                      name="extra_males"
                      variant="outlined"
                      placeholder={row.extra_males  !=="FALSE" ? "" : "0"}
                      value={row.extra_males === "TRUE" ? "" : row.extra_males }
                      disabled={row.extra_males !=="FALSE"?false:true}
                      onChange={e =>
                        handleFieldChange(e, i, activities, activities)
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="number"
                      name="extra_females"
                      variant="outlined"
                      placeholder={row.extra_females !=="FALSE" ? "" : "0"}
                      disabled={row.extra_females !=="FALSE"?false:true}
                      value={
                        row.extra_females ===  "TRUE" ? "" : row.extra_females
                      }
                      onChange={e => handleFieldChange(e, i, activities)}
                    />
                  </TableCell>
                </>
              )}
            </>
          )}
          {location === QualMapping.ENTRY && (
            <>
              {venue === "Visiting" && (
                <TableCell align="center">
                  <Select
                    value={row.liveorfilmed}
                    name="liveorfilmed"
                    onChange={e => handleFieldChange(e, i, activities)}
                    sx={{ width:"6.5rem" }}
                  >
                    <MenuItem sx={{ width:"6.5rem" }} value={"live"}>Live</MenuItem>
                    <MenuItem sx={{ width:"6.5rem" }} value={"filmed"}>Filmed</MenuItem>
                  </Select>
                </TableCell>
              )}
              {venue !== "Visiting" && (
                <TableCell align="center">
                  <Checkbox
                    {...label}
                    name="being_filmed"
                    checked={row.being_filmed.ticked}
                    value={row.being_filmed.ticked?'on':"off"}
                    disabled={!row.being_filmed.enabled}
                    onChange={e => handleFieldChange(e, i, activities)}
                  />
                </TableCell>
              )}
            </>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};
