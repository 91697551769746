import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./components/common/Header";
import { theme } from "./utils/Theme";
import { BrowserRouter } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { UserContextProvider } from "./context/UserContext";
import { AppRoute } from "./Routes";
import { QualProvider } from "./context/QualContext";
import { MainContextProvider } from "./context/Context";
import ErrorBoundary from "./components/common/ErrorBoundary";
import { MarkSubmissionProvider } from "./context/MarkSubmissionContext";
import { Provider } from "react-redux";
import store from "./store/store";
import axios from "axios";

const App = () => {
  const auth = useAuth();
  useEffect(() => {
    const handleAuthentication = async () => {
      if (
        !auth.isAuthenticated &&
        !auth.isLoading &&
        !auth.error &&
        !auth.activeNavigator
      ) {
        await auth.signinRedirect();
      }

      if (auth.error) {
        sessionStorage.clear();
        await auth.signinRedirect();
      }
    };

    handleAuthentication();
  }, [auth]);

  // method to call active API to retain session
  const getSessionDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}getseries/active`,
        {
          headers: {
            Authorization: `Bearer ${auth?.user?.access_token}`,
          },
        }
      );
      if (response.status === 200) {
        console.log('success')
      }
    } catch (error) {
      console.log("response", error);
    }
  };

  const MINUTE_MS = 60000;
  useEffect(() => {
    const interval = setInterval(() => {
      getSessionDetails()
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [auth])

  // if (
  //   !auth.isAuthenticated &&
  //   !auth.isLoading &&
  //   !auth.error &&
  //   !auth.activeNavigator
  // ) {
  //   auth.signinRedirect();
  // }

  // if (auth.error) {
  //   sessionStorage.clear();
  //   auth.signinRedirect();
  // }

  if (auth.isAuthenticated) {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <UserContextProvider>
              <MainContextProvider>
                <QualProvider>
                  <CssBaseline />
                  <BrowserRouter>
                    <Header />
                    <AppRoute />
                  </BrowserRouter>
                </QualProvider>
              </MainContextProvider>
            </UserContextProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </Provider>
    );
  }
};

export default App;
