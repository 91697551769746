import React, { useEffect } from "react";
import Confirm from "../markSubmissionSteps/Confirm";
import Success from "../markSubmissionSteps/Success";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, StepLabel, Step, Stepper } from "@mui/material";
import "./StepForm.css";
import { marksSubmissionLabels } from "../../utils/constants";
import { useSelector } from "react-redux";
import { selectPemifData } from "../../store/reducers/pemifReducer";
import StepOne from "../markSubmissionSteps/StepOne/StepOne";
import StepTwo from "../markSubmissionSteps/StepTwo/StepTwo";
import { selectCandidates } from "../../store/reducers/candidateReducer";

const handleSteps = step => {
  switch (step) {
    case 0:
      return <StepOne />;
    case 1:
      return <StepTwo />;
    case 2:
      return <Confirm />;
    default:
      throw new Error("Unknown step");
  }
};

function MarksSubmissionForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const { activeStep } = useSelector(selectPemifData);
  const { candidates } = useSelector(selectCandidates);
  useEffect(() => {
    if (!candidates.length) navigate("/home/markSubmission");
  }, [candidates.length]);
  return (
    <>
      <Container component="main" maxWidth="lg" sx={{ mb: 4}}>
        {activeStep === marksSubmissionLabels.length ? (
          <Success />
        ) : (
          <>
            <Container component="main" maxWidth="sm" sx={{ mb: 4}}>
              <Stepper activeStep={activeStep} sx={{ py: 3 }} alternativeLabel>
                {marksSubmissionLabels.map(label => (
                  <Step key={label}>
                    <StepLabel></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Container>
            {handleSteps(activeStep, location.state?.name)}
          </>
        )}
      </Container>
    </>
  );
}
export default MarksSubmissionForm;
