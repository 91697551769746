import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  activeStep: 0,
  session_id: "",
  session_name: "",
  session_year: "",
  centre_number: "",
  centre_name: "",
  moderator_name: "",
  variant: "outlined",
  margin: "normal",
  is_vaf_submitted: false,
  status: "idle",
  submitStatus: "",
  errorMessage: "",
  validationErrorList: [],
  reviewPageError: false,
  isAckPage:false
};
export const fetchModeratorDetails = createAsyncThunk(
  "pemifData/fetchData",
  async ({ centre, qual, session, token }) => {
    // REACT_APP_API_BASEURL= https://pe-portal-api.pesubmissions-dev.ocr.org.uk/api/
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASEURL}` +
      `getmoderatordetails?centre_number=${centre}&qualification_id=${qual}&session_id=${session}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  }
);
export const saveSubmit = createAsyncThunk(
  "pemifData/saveSubmit",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MARK_BASEURL}` +
        `markssubmissions/save_submit`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response || "Failed to submit data");
    }
  }
);
const pemifDataSlice = createSlice({
  name: "pemifData",
  initialState: initialState,
  reducers: {
    handleNext: state => {
      return { ...state, activeStep: state.activeStep + 1, reviewPageError: false,isAckPage: false };
    },
    handleAckPage: (state, action) => {
      return { ...state, isAckPage: true }
    },
    handleReviewPageError: (state, action) => {
      return { ...state, reviewPageError: action.payload }
    },

    handleBack: state => {
      return { ...state, activeStep: state.activeStep - 1,reviewPageError: false };
    },
    handleFieldData: (state, action) => {
      return {
        ...state,
        session_id: action.payload.data[0],
        session_name: action.payload.data[1],
        centre_number: action.payload.data[2],
        centre_name: action.payload.data[3],
        session_year: action.payload.data[4],
      };
    },
    handleModeratorName: (state, action) => {
      return {
        ...state,
        moderator_name: action.payload.data,
        is_vaf_submitted: true,
      };
    },
    resetPemifData: state => {
      state.activeStep = 0;
      state.status = "idle";
      state.submitStatus = "";
      state.errorMessage = "";
      state.validationErrorList = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchModeratorDetails.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchModeratorDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.moderator_name = action.payload.moderator_name;
      })
      .addCase(fetchModeratorDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(saveSubmit.pending, state => {
        state.submitStatus = "loading";
      })
      .addCase(saveSubmit.fulfilled, (state, action) => {
        state.submitStatus = "succeeded";
      })
      .addCase(saveSubmit.rejected, (state, action) => {
        state.submitStatus = "failed";
        state.validationErrorList = action.payload?.data?.violationList
          ? action.payload.data.violationList
          : [];
        state.errorMessage = action.payload?.data?.errorMessage
          ? action.payload?.data?.errorMessage
          : "";
      });
  },
});
export const selectPemifData = state => state.pemifData;
export const {
  handleNext,
  handleBack,
  handleFieldData,
  handleModeratorName,
  resetPemifData,
  handleReviewPageError,
  handleAckPage
} = pemifDataSlice.actions;
export default pemifDataSlice;
