import { MarkSubmissionPopup } from "./SubmittedPopup"; // Import your custom component
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Tooltip } from "@mui/material";
import { QualContext } from "../../context/QualContext";
import { UserContext } from "../../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { selectPemifData } from "../../store/reducers/pemifReducer";
import { fetchCandiates } from "../../store/reducers/candidateReducer";
import { resetActivity } from "../../store/reducers/activityReducer";
import { resetRole } from "../../store/reducers/roleReducer";
import { resetPemifData } from "../../store/reducers/pemifReducer";
import { exceptionActivityReset } from "../../store/reducers/exceptionalActivityReducer";
import { resetEventData } from "../../store/reducers/eventReducer";
import axios from "axios";
 
export const MarkLandingPage = ({ title, token }) => {
  const [tooltipOpen, setTooltipOpen] = useState({
    440: false,
    159: false,
    1787: false,
    97: false,
  });
  const [filledStatus, setFilledStatus] = useState({});
  const [selectedQual, setSelectedQual] = useState('')
  const { markSubmissionQual } = useContext(QualContext);
  const { centre_number } = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { session_id } = useSelector(selectPemifData);

  let currentDate = new Date();
  let endDate = new Date('2025-03-15');

  const checkFilledStatus = async (qualificationId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MARK_BASEURL}` +
          `markssubmissions/getcandidatedetails?centre_number=${centre_number}&qualification_id=${qualificationId}&session_id=${session_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
   
      setFilledStatus((prev) => ({
        ...prev,
        [qualificationId]: currentDate >= endDate || !response.data.candidate_details?.length,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 
  useEffect(() => {
    if (centre_number && session_id) {
      markSubmissionQual.forEach((qual) => checkFilledStatus(qual.qualificationId));
    }
  }, [centre_number, session_id]);

  const handleMarkSubmission = async (event, qualificationId) => {
    const isFilled = filledStatus[qualificationId]; // Check if the qualification is already filled
    if (isFilled) {
      // Show tooltip for filled forms
      setTooltipOpen((prev) => ({
        ...prev,
        [qualificationId]: true,
      }));
      return; // Prevent navigation if the form is filled
    }

    setTooltipOpen({
      440: false,
      159: false,
      1787: false,
      97: false,
    });
    dispatch(resetActivity());
    dispatch(resetRole());
    dispatch(resetPemifData());
    dispatch(exceptionActivityReset());
    dispatch(resetEventData());

    try {
      const res = await dispatch(
        fetchCandiates({
          centre: centre_number,
          qual: qualificationId,
          session: session_id,
          token: token,
        })
      );
      if (currentDate >= endDate || !res.payload.candidate_details?.length) {
        // Show tooltip if form is already filled
        setTooltipOpen((prev) => ({
          ...prev,
          [qualificationId]: true,
        }));
      } else {
        // Navigate to the form if not filled
        setTooltipOpen({
          440: false,
          159: false,
          1787: false,
          97: false,
        });
        navigate("/home/markSubmission/form", {
          state: { name: event.target.name },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        alignItems:"center",
        gap:"10px",
        height: "auto",
      }}
    >
      {title === "Marks submission" &&
        markSubmissionQual.map((qual, index) => {
          const isFilled = filledStatus[qual.qualificationId]; // Check if this qualification is already filled

          return (
            <Tooltip
              key={index}
              title={isFilled ? <MarkSubmissionPopup qual={qual} /> : ""} // Show tooltip only for filled forms
              arrow
              disableFocusListener
              placement="top"
              open={tooltipOpen[qual.qualificationId]}
              onClose={() => setTooltipOpen((prev) => ({ ...prev, [qual.qualificationId]: false }))} // Hide tooltip on close
            >
              <Button
                key={index}
                variant="contained"
                name={qual.qualificationId}
                onClick={(event) => {
                  setSelectedQual(qual.qualificationId[0])
                  setTooltipOpen(
                   { 440: false,
                    159: false,
                    1787: false,
                    97: false})
                }
                }
                sx={{
                  height: "3rem",
                  width: "20%",
                  minWidth:"20%",
                  margin: "0 10px", // Add some margin between buttons
                  borderRadius: selectedQual == qual.qualificationId[0] ? '4px' : '0px',
                  border:selectedQual == qual.qualificationId[0] ? '5px solid #C0C0C0' : '0px',
                  padding: selectedQual == qual.qualificationId[0] ? '8px 16px' : '0px',
                }}
              >
                {qual.qualificationDisplayNameButton}
              </Button>
            </Tooltip>
          );
        })}


    </Grid>
    <Grid
    item
    xs={12}
    sx={{
      display: "flex",
      justifyContent: "flex-end",
      position:"relative",
      marginTop:"20px"
    }}
  >{
    selectedQual &&  <Button variant="contained"  sx={{position:"absolute",right:"0" , marginTop:"20px", backgroundColor:"#2E7D32"}}  onClick={ () => handleMarkSubmission({target: {name: selectedQual.toString()}}, selectedQual.toString())}>    Next
  </Button>
  }

    </Grid>
    </>

  );
};
