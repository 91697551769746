import React, { useCallback, useContext } from "react";
import StepActions from "../common/StepActions";
import { Typography, Box, TextField, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { MainContext } from "../../context/Context";

export default function SecondStep() {
  const location = useLocation();
  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    handleSave,
    variant,
    margin,
  } = useContext(MainContext);

  const {
    moderation_contact_email,
    moderation_contact_name,
    moderation_contact_telephone,
    moderation_contact_role,
    moderation_contact_extension,
  } = formValues;

  const isError = useCallback(
    () =>
      Object.keys({
        moderation_contact_email,
        moderation_contact_name,
        moderation_contact_telephone,
        moderation_contact_role,
        moderation_contact_extension,
      }).some(
        name =>
          (formValues[name].required && !formValues[name].value) ||
          formValues[name].error
      ),
    [
      formValues,
      moderation_contact_email,
      moderation_contact_name,
      moderation_contact_telephone,
      moderation_contact_role,
      moderation_contact_extension,
    ]
  );

  return (
    <>
      <Box sx={{ mt: 5, mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          PE department contact information
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Name of PE moderation contact*
          </Typography>

          <TextField
            variant={variant}
            margin={margin}
            name="moderation_contact_name"
            value={moderation_contact_name.value}
            onChange={handleChange}
            error={!!moderation_contact_name.error}
            helperText={moderation_contact_name.error}
            required={moderation_contact_name.required}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Role of PE moderation contact*
          </Typography>

          <TextField
            variant={variant}
            margin={margin}
            name="moderation_contact_role"
            value={moderation_contact_role.value}
            onChange={handleChange}
            error={!!moderation_contact_role.error}
            helperText={moderation_contact_role.error}
            required={moderation_contact_role.required}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Email for PE moderation contact*
          </Typography>

          <TextField
            variant={variant}
            margin={margin}
            name="moderation_contact_email"
            value={moderation_contact_email.value}
            onChange={handleChange}
            error={!!moderation_contact_email.error}
            helperText={moderation_contact_email.error}
            required={moderation_contact_email.required}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2">
            Telephone number for PE moderation contact*
          </Typography>

          <TextField
          type="number"
            variant={variant}
            margin={margin}
            name="moderation_contact_telephone"
            value={moderation_contact_telephone.value}
            onChange={handleChange}
            error={!!moderation_contact_telephone.error}
            helperText={moderation_contact_telephone.error}
            required={moderation_contact_telephone.required}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Extension number</Typography>

          <TextField
            variant={variant}
            margin={margin}
            name="moderation_contact_extension"
            value={moderation_contact_extension.value}
            onChange={handleChange}
            error={!!moderation_contact_extension.error}
            helperText={moderation_contact_extension.error}
            sx={{ width: "25%" }}
          />
        </Grid>
      </Grid>

      <StepActions
        isError={isError}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSave={handleSave}
      />
    </>
  );
}
