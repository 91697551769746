import React, { useState, useMemo } from "react";
import {
  handleCandidateStatus,
  selectCandidates,
} from "../../../store/reducers/candidateReducer";
import { StepOneHeaderDetails } from "./StepOneHeaderDetails";
import StepActionsMarksSubmission from "../StepActionsMarksSubmission";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { QualMapping } from "../../../utils/constants";
import CandidateReviewTable from "./CandidateReviewTable";

export default function StepOne() {
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <div>
      <StepOneHeaderDetails/>
      <CandidateReviewTable />
      <StepActionsMarksSubmission isError={() =>{}} isFirst={true} customStyle={{ marginTop: '40px' }}
       dividerStyle={{marginTop:"-50px"}} />
    </div>
  );
}
